<style scoped>
    .language-more{
        -webkit-transition: all .3s cubic-bezier(.25, 1.15, .35, 1.15);
        -moz-transition: all .3s cubic-bezier(.25, 1.15, .35, 1.15);
        -o-transition: all .3s cubic-bezier(.25, 1.15, .35, 1.15);
        -ms-transition: all .3s cubic-bezier(.25, 1.15, .35, 1.15);
        transition: all .3s cubic-bezier(.25, 1.15, .35, 1.15);
        opacity: 0;
        transform: translateY(-20px);
    }
    .language-more.active{
        opacity: 1;
        transform: translateY(0px);
    }
</style>
<template>
    <!-- 语言选择 -->
    <div class="bg-dark bg-opacity-75 text-light rounded overflow-hidden">
        <div class="px-3 py-2" @click.stop="showMoreLang" role="button">
            <img class="img-fluid w-1rem" :src="require('@/assets/images/login/'+$i18n.locale+'.jpg')" />
            <span class="position-relative fw-bold ms-1 vertical-middle">{{LANG[$i18n.locale]}}</span>
        </div>
        <div class="language-more" :class="langClass" v-show="isShowMoreLang">
            <div v-for="(item,key) of LANG" role="button" @click="$i18n.locale=key" v-show="key==$i18n.locale?false:true" :key="(item,key)" class="px-3 py-2 border-top" :class="key==$i18n.locale?'selected':''" data-value='zh'>
                <img class="img-fluid w-1rem" :src="require('@/assets/images/login/'+key+'.jpg')" alt="">
                <span class="position-relative fw-bold ms-1">{{item}}</span>
            </div>
        </div>
    </div>
</template>
<script>
import {LANG} from "@/assets/js/config.js"
export default {
    data() {
        return {
            LANG,
            isShowMoreLang:false,//是否显示其他语言
            langClass:"",//语言class名称，为了有延迟效果
        };
    },
    mounted() {
        
    },
    methods: {
        /**
         * 显示其他语言
         */
        showMoreLang(){
            if(this.isShowMoreLang){
                this.isShowMoreLang=false;
                this.langClass="";
            }else{
                this.isShowMoreLang=true;
                setTimeout(() => {
                    this.langClass="active";
                }, 10);
            }
            let fn=()=>{
                this.isShowMoreLang=false;
                this.langClass="";
                document.removeEventListener("click",fn);
            }
            document.addEventListener("click",fn);
        },
    },
};
</script>