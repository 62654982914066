<!-- 纵向导航栏 -->
<style>
    .logo{
        width: 8rem; 
        z-index: 1000;
    }
</style>
<template>
    <div class="d-flex flex-column">
        <img class="d-none d-sm-block logo mx-4 py-2" src="logo.png" alt="">
        <div class="rounded-3 border d-flex flex-column mx-2 p-2">
            <div class="d-flex align-items-center bg-dark px-1 rounded-pill text-light mb-1">
                <i class="bi bi-person-circle me-2 text-info"></i>
                <span>{{userInfo.id}}</span>
            </div>
            <div v-if="!($route.name=='share'||gameModel==GAME_MODEL.tel)" class="d-flex align-items-center bg-dark px-1 rounded-pill text-light mb-1">
                <i class="bi bi-coin me-2 text-yellow"></i>
                <span class="me-1 align-middle fw-bold">{{$t("nav.realMoney")}}</span>
                <span class="align-middle">{{userInfo.realCoin}}</span>
            </div>
            <div v-if="!($route.name=='share'||gameModel==GAME_MODEL.tel)" class="d-flex align-items-center bg-dark px-1 rounded-pill text-light mb-1">
                <i class="bi bi-coin me-2 text-yellow"></i>
                <span class="me-1 align-middle fw-bold">{{$t("nav.esportsMoney")}}</span>
                <span class="align-middle">{{userInfo.esportsCoin}}</span>
            </div>
            <div class="d-flex justify-content-center small">
                <button type="button" class="btn btn-success btn-sm rounded-pill mx-1 py-0" @click="$emit('show-modal',$t('personal.recharge'))">{{$t('personal.recharge')}}</button>
                <button type="button" class="btn btn-secondary btn-sm rounded-pill mx-1 py-0" @click="$emit('show-modal',$t('personal.withdraw'))">{{$t('personal.withdraw')}}</button>
            </div>
        </div>
        <div class="d-flex flex-column flex-grow-1 justify-content-between mx-2 py-2">
            <span class="btn btn-sm btn-navy rounded-5 text-light rounded-5" @click="$root.setFullscreen">
                <i class="bi bi-arrows-fullscreen me-1"></i>{{isFullscreen?$t("login.unFullscreen"):$t("login.fullscreen")}}
            </span>
            <span class="btn btn-sm btn-navy rounded-5 text-light rounded-5" @click="$root.goPage('help',$root.callInfo,{id:userInfo.guid,name:userInfo.id,type:1,guid:guid})">
                <i class="bi bi-chat-dots-fill me-1"></i>{{$t('common.onlineServices')}}
            </span>
            <span class="btn btn-sm btn-navy rounded-5 text-light rounded-5" @click="$emit('show-modal',$t('common.userRecords'))">
                <i class="bi bi-journal-text me-1"></i>{{$t('common.userRecords')}}
            </span>
            <span class="btn btn-sm btn-navy rounded-5 text-light rounded-5" @click="$emit('show-modal',$t('personal.updateSource'))">
                <i class="bi bi-piggy-bank me-1"></i>{{$t('personal.updateSource')}}
            </span>
            <span class="btn btn-sm btn-navy rounded-5 text-light rounded-5" @click="$emit('show-modal',$t('nav.network'))">
                <i class="bi bi-hdd-network me-1"></i>{{$t('nav.network')}}
            </span>
            <span class="btn btn-sm btn-navy rounded-5 text-light rounded-5" @click="$emit('show-modal',$t('common.vipShare'))">
                <i class="bi bi-people me-1"></i>{{$t('common.vipShare')}}
            </span>
            <span class="btn btn-sm btn-navy rounded-5 text-light rounded-5" @click="$emit('show-modal',$t('common.safeSetting'))">
                <i class="bi bi-gear-fill me-1"></i>{{$t('common.safeSetting')}}
            </span>
            <span class="btn btn-sm btn-navy rounded-5 text-light rounded-5" @click="$emit('show-modal',$t('nav.sound'))">
                <i class="bi bi-volume-up me-1"></i>{{$t('nav.sound')}}
            </span>
            <span class="btn btn-sm btn-navy rounded-5 text-light rounded-5" @click="$emit('show-modal',$t('common.questionFeedBack'))">
                <i class="bi bi-question-circle me-1"></i>{{$t('common.questionFeedBack')}}
            </span>
            <div class="d-flex justify-content-between small">
                <div class="d-flex align-items-center text-light" @click="$root.logout">
                    <i class="bi bi-box-arrow-left d-flex me-1"></i>
                    <span>退出</span>
                </div>
                <div class="d-flex align-items-center text-light" @click="$root.goPage('home')">
                    <span>{{$t("nav.home")}}</span>
                    <i class="bi bi-house d-flex ms-1"></i>
                </div>
            </div>
        </div>
        
    </div>
</template>
<script>
    import { GAME_MODEL } from "@/assets/js/config.js"
    export default{
        data() {
            return {
                GAME_MODEL
            };
        },
        props:{
            isFullscreen:{
                type:Boolean,
                default:false
            },//当前是否全屏
        },
        computed: {
            userInfo() {
                return this.$store.state.userInfo;
            },
            gameModel(){
                return this.$store.state.gameModel;
            }
        },
    }
</script>