import { createStore } from 'vuex'
import {MUTATION_TYPE,GAME_ID,GAME_MODEL} from "@/assets/js/config.js"

export default createStore({
	state:{
		userInfo:{
			id:"",//用户名
			guid:"",//用户guid
			realCoin:0,//真人币
			esportsCoin:0//竞技币
		},//用户信息
		activeGame:GAME_ID.bac,//当前首页激活的游戏
		tabGame:0,//换台列表中选中的游戏项
		roomInfo:{},//台面信息
		videoLineList:[],//视频线路列表
		currVideoLine:"",//当前视频线路id
		audioSwitch:{
			bgAudio:true,//背景音效开关
			startAudio:true,//开始/结束音效开关
			resultAudio:true,//开注音效
			tenAudio:true,//最后10秒音效
		},//音效开关
		gameList:[],//游戏列表
		gameModel:GAME_MODEL.normal,//游戏模式，默认经典模式
	},
	//state派生状态（如过滤、计数），类似计算属性
	getters: {
		// doneTest(state,getters){
		// 	return state.test.filter(v=>v<3);
		// },
	},
	//提交变更
	mutations: {
		/**
		 * 设置用户信息
		 */
		[MUTATION_TYPE.setUserInfo](state,payload){
			Object.assign(state.userInfo,payload.userInfo);
		},
		/**
		 * 设置当前首页激活的游戏
		 */
		[MUTATION_TYPE.setActiveGame](state,payload){
			state.activeGame=payload.activeGame;
		},
		/**
		 * 设置台面信息
		 */
		[MUTATION_TYPE.setRoomInfo](state,payload){
			if(payload.roomInfo){
				Object.assign(state.roomInfo,payload.roomInfo);
			}else{
				state.roomInfo={};
			}
		},
		/**
		 * 设置换台列表中选中的游戏项
		 */
		[MUTATION_TYPE.setTabGame](state,payload){
			state.tabGame=payload.tabGame;
		},
		/**
		 * 设置视频线路列表
		 */
		[MUTATION_TYPE.setVideoLineList](state,payload){
			state.videoLineList=payload.videoLineList;
		},
		/**
		 * 设置当前视频线路ID
		 */
		[MUTATION_TYPE.setCurrVideoLine](state,payload){
			state.currVideoLine=payload.currVideoLine;
		},
		/**
		 * 设置音效开关
		 */
		[MUTATION_TYPE.setAudioSwitch](state,payload){
			Object.assign(state.audioSwitch,payload.audioSwitch);
		},
		/**
		 * 设置游戏列表
		 */
		[MUTATION_TYPE.setGameList](state,payload){
			state.gameList=payload.gameList;
		},
		/**
		 * 设置游戏模式
		 */
		[MUTATION_TYPE.setGameModel](state,payload){
			state.gameModel=payload.gameModel;
		}
	},

	//
	actions: {
	},
	//模块化
	modules: {
	}
})
