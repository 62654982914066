<style>
    .bg-navy{
        background:linear-gradient(0deg,#00345b 10%,#005ea6 80%); 
        box-shadow:0 0.1rem 0.1rem 0.05rem #0e0e0e, inset 0 0.05rem 0px 0px rgba(255, 255, 255, 0.7);
    }
    .btn-navy.active{
        box-shadow: 0 0 0 0.05rem #0e0e0e !important;
        transform: translateY(.1rem);
    }
    @media (min-width: 576px){
        .list-item:last-child{
            border-bottom: 1px solid #dee2e6 !important;
        }
        .list-item:nth-of-type(odd) > * {
            background: rgba(0, 0, 0, 0.05);
        }
        .list-item:hover {
            background: rgba(0, 0, 0, 0.075);
        }
    }
</style>
<template>
    <div v-if="currModal" class="d-flex justify-content-center align-items-center position-absolute top-0 bottom-0 end-0 start-0 bg-dark bg-opacity-50 index-1051">
        <div class="d-flex flex-column rounded-3 overflow-hidden shadow">
            <div class="bg-navy d-flex justify-content-between py-2 px-3 text-light">
                <span>{{currModal}}</span>
                <i class="bi bi-x-octagon" @click="$emit('close-modal')"></i>
            </div>
            <div class="d-flex p-3" style="background: #005ea6">
                <!-- 音效设置 -->
                <div v-if="currModal==$t('nav.sound')" class="d-flex flex-wrap text-light">
                    <div class="d-flex col-12 col-sm-6 mb-2">
                        <div class="form-check form-switch ps-0 col">
                            <div class="d-flex align-items-center justify-content-between justify-content-sm-start">
                                <input class="form-check-input fs-4 m-0 me-sm-2" @change="changeAudioSwitch('bgAudio',$event.target.checked)" :checked="audioSwitch.bgAudio" type="checkbox" role="switch" id="bgAudio">
                                <label class="form-check-label" for="bgAudio">{{$t("nav.bgAudio")}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex col-12 col-sm-6 mb-2">
                        <div class="form-check form-switch ps-0 col">
                            <div class="d-flex align-items-center justify-content-between justify-content-sm-start">
                                <input class="form-check-input fs-4 m-0 me-sm-2" @change="changeAudioSwitch('startAudio',$event.target.checked)" :checked="audioSwitch.startAudio" type="checkbox" role="switch" id="startAndEndAudio">
                                <label class="form-check-label" for="startAndEndAudio">{{$t("nav.startAudio")}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex col-12 col-sm-6 mb-2 mb-sm-0">
                        <div class="form-check form-switch ps-0 col">
                            <div class="d-flex align-items-center justify-content-between justify-content-sm-start">
                                <input class="form-check-input fs-4 m-0 me-sm-2" @change="changeAudioSwitch('resultAudio',$event.target.checked)" :checked="audioSwitch.resultAudio" type="checkbox" role="switch" id="resultAudio">
                                <label class="form-check-label" for="resultAudio">{{$t("nav.resultAudio")}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex col-12 col-sm-6 mb-2 mb-sm-0">
                        <div class="form-check form-switch ps-0 col">
                            <div class="d-flex align-items-center justify-content-between justify-content-sm-start">
                                <input class="form-check-input fs-4 m-0 me-sm-2" @change="changeAudioSwitch('tenAudio',$event.target.checked)" :checked="audioSwitch.tenAudio" type="checkbox" role="switch" id="tenAudio">
                                <label class="form-check-label" for="tenAudio">{{$t("nav.tenAudio")}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 线路选择 -->
                <div v-if="currModal==$t('nav.network')" class="d-flex flex-wrap text-light">
                    <div class="row text-center text-light">
                        <div class="col-12 col-sm-6 mb-2" v-for="(item,i) in videoLineList" :key="(item,i)">
                            <div class="btn-navy rounded py-2" :class="(item.id==currVideoLine?'active text-info':'')" @click="$root.setCurrVideoLine(item.id)" role="button">{{item.name}}</div>
                        </div>
                    </div>
                </div>
                <!-- 充值 -->
                <div v-if="currModal==$t('personal.recharge')" class="d-flex flex-wrap text-light">
                    <!-- 表单填写 -->
                    <form v-show="isShowRechargeForm">
                        <div class="p-3 p-sm-4 border border-1 rounded">
                            <div class="d-flex flex-column flex-sm-row mb-3">
                                <div class="col-12 col-sm-3">
                                    <label for="rechargeAmount" class="col-form-label">{{$t("personal.rechargeAmount")}}：</label>
                                </div>
                                <div class="col">
                                    <input type="number" class="form-control bg-light" id="rechargeAmount" v-model="rechargeAmount" :placeholder="$t('personal.rechargeAmount')" name="number">
                                    <div class="form-text">{{$t("personal.rechargeRange")}}{{rechargeLimit.min}}~{{rechargeLimit.max}}</div>
                                </div>
                            </div>
                            <div class="d-flex flex-column flex-sm-row mb-3">
                                <div class="col-12 col-sm-3">
                                    <label class="col-form-label">{{$t("personal.rechargeType")}}：</label>
                                </div>
                                <div class="col">
                                    <div class="form-check form-check-inline col-form-label">
                                        <input class="form-check-input" type="radio" name="rechargeType" v-model="rechargeType" :value="GAME_TYPE.real" id="rechargeTypeReal">
                                        <label class="form-check-label" for="rechargeTypeReal">{{$t("nav.realMoney")}}</label>
                                    </div>
                                    <div class="form-check form-check-inline col-form-label">
                                        <input class="form-check-input" type="radio" name="rechargeType" v-model="rechargeType" :value="GAME_TYPE.esports" id="rechargeTypeEsport">
                                        <label class="form-check-label" for="rechargeTypeEsport">{{$t("nav.esportsMoney")}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-column flex-sm-row mb-3">
                                <div class="col-12 col-sm-3">
                                    <label class="col-form-label">{{$t("personal.payment")}}：</label>
                                </div>
                                <div class="col">
                                    <div v-for="i in rechargeFundsType" :key="i" class="form-check-inline px-2 rounded" :class="[payment==i.id?'border':'border-1 border-transparent']" @click="changeFundsType('recharge',i.id)">
                                        <input :id="'rechargePayment_'+i.id" class="btn-check" type="radio" autocomplete="off" name="payment" :value="i.id">
                                        <label class="form-check-label fs-2" :for="'rechargePayment_'+i.id" role="button">
                                            <i v-if="i.id==FUNDS_RECHARGE_TYPE.alipay" class="bi bi-alipay text-primary"></i>
                                            <i v-else-if="i.id==FUNDS_RECHARGE_TYPE.wechat" class="bi bi-wechat text-success"></i>
                                            <i v-else-if="i.id==FUNDS_RECHARGE_TYPE.bank" class="bi bi-credit-card text-dark"></i>
                                            <i v-else-if="i.id==FUNDS_RECHARGE_TYPE.virtual" class="bi bi-currency-bitcoin text-info"></i>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-column flex-sm-row mb-3">
                                <div class="col-12 col-sm-3">
                                    <label class="col-form-label">{{$t("personal.currency")}}：</label>
                                </div>
                                <div class="col">
                                    <div v-for="item in currencyList" :key="item" class="form-check form-check-inline col-form-label">
                                        <input :id="'rechargeCurrency_'+item.id" class="form-check-input" type="radio" name="rechargeCurrency" v-model="rechargeCurrency" :value="item.id" @change="changeCurrency(item.id)">
                                        <label class="form-check-label" :for="'rechargeCurrency_'+item.id">{{item.name}}</label>
                                    </div>
                                </div>
                            </div>
                            <button type="button" @click="submitEvent('recharge')" class="btn btn-primary text-light">{{$t("personal.submit")}}</button>
                        </div>
                    </form>
                    <!-- 成功反馈 -->
                    <div v-show="!isShowRechargeForm">
                        <div class="d-flex flex-column align-items-center">
                            <div class="text-success display-3 mb-2">
                                <i class="bi bi-check-circle-fill"></i>
                            </div>
                            <span class="text-success fw-bold fs-4">{{$t("personal.rechargeSuccess")}}</span>
                            <div class="">
                                <span class="text-white">{{$t("personal.qrcodeIs")}}：</span>
                                <span class="text-danger fs-1">{{rechargeCode}}</span>
                            </div>
                            <div class="text-danger mb-2">{{$t("personal.codeToRemark")}}</div>
                            <div class="col" v-show="isShowQrcode">
                                <div class="d-flex justify-content-center">
                                    <div class="col-10 col-sm-6 col-md-7 col-lg-8 col-xl-9">
                                        <vue-qr class="img-thumbnail bg-transparent rounded" :margin="10" colorDark="#0dcaf0" :text="rechargeQrcode"></vue-qr>
                                    </div>
                                </div>
                                <div class="mt-2 mb-3 text-center">
                                    <span class="text-white">{{$t("personal.scanOrClick")}}</span>
                                    <button class="btn btn-primary btn-sm mx-1">{{$t("personal.hear")}}</button>
                                    <span class="text-white">{{$t("personal.toRecharge")}}</span>
                                </div>
                            </div>
                            <div class="card mb-3" v-show="isShowBankInfo">
                                <div class="card-body border border-1 rounded p-0">
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item list-group-item-primary">
                                            <span class="fw-bold">{{$t("personal.name")}}：</span>
                                            <span>{{rechargeSuccBankName}}</span>
                                        </li>
                                        <li class="list-group-item list-group-item-primary">
                                            <span class="fw-bold">{{$t("personal.account")}}：</span>
                                            <span>{{rechargeSuccBankAccount}}</span>
                                        </li>
                                        <li class="list-group-item list-group-item-primary">
                                            <span class="fw-bold">{{$t("personal.bank")}}：</span>
                                            <span>{{rechargeSuccBankBank}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <button type="button" class="btn btn-primary" @click="isShowRechargeForm=true">{{$t("personal.back")}}</button>
                        </div>
                    </div>
                </div>
                <!-- 提现 -->
                <div v-if="currModal==$t('personal.withdraw')" class="p-3 p-sm-4 border border-1 rounded text-light">
                    <div class="mb-1 text-center text-danger">{{$t("personal.withdrawHandlingFee")}}</div>
                    <div class="d-flex flex-column flex-sm-row mb-3">
                        <div class="col-12 col-sm-3">
                            <label for="withdrawAmount" class="col-form-label">{{$t("personal.withdrawAmount")}}：</label>
                        </div>
                        <div class="col">
                            <input type="number" class="form-control bg-light" v-model="withdrawAmount" id="withdrawAmount" :placeholder="$t('personal.withdrawAmount')" name="withdrawAmount">
                            <div class="form-text">{{$t("personal.withdrawRange")}}{{withdrawLimit.min}}~{{withdrawLimit.max}}</div>
                        </div>
                    </div>
                    <div class="d-flex flex-column flex-sm-row mb-3">
                        <div class="col-12 col-sm-3">
                            <label for="pwd" class="col-form-label">{{$t("personal.withdrawal")}}：</label>
                        </div>
                        <div class="col">
                            <div v-for="i in withdrawFundsType" :key="i" class="form-check-inline px-2 rounded" :class="[withdrawal==i.id?'border':'border-1 border-transparent']" @click="changeFundsType('withdraw',i.id)">
                                <input :id="'withdrawal_'+i.id" class="btn-check" type="radio" autocomplete="off" name="withdrawal" :value="i.id">
                                <label class="form-check-label fs-2" :for="'withdrawal_'+i.id" role="button">
                                    <i v-if="i.id==FUNDS_WITHDRAW_TYPE.alipay" class="bi bi-alipay text-primary"></i>
                                    <i v-else-if="i.id==FUNDS_WITHDRAW_TYPE.wechat" class="bi bi-wechat text-success"></i>
                                    <i v-else-if="i.id==FUNDS_WITHDRAW_TYPE.bank" class="bi bi-credit-card text-dark"></i>
                                    <i v-else-if="i.id==FUNDS_WITHDRAW_TYPE.virtual" class="bi bi-currency-bitcoin text-info"></i>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column flex-sm-row mb-3">
                        <div class="col-12 col-sm-3">
                            <label for="email" class="col-form-label">{{$t("personal.accepted")}}：</label>
                        </div>
                        <div class="col">
                            <select class="form-select bg-light" v-model="accepted">
                                <option v-for="i in acceptedList" :key="i.id" :value="i.id">{{getAcceptedTxt(i)}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="d-flex flex-column flex-sm-row mb-3">
                        <div class="col-12 col-sm-3">
                            <label for="pwd" class="col-form-label">{{$t("personal.withdrawType")}}：</label>
                        </div>
                        <div class="col">
                            <div class="form-check form-check-inline col-form-label">
                                <input class="form-check-input" type="radio" name="withdrawType" v-model="withdrawType" :value="GAME_TYPE.real" id="withdrawTypeReal">
                                <label class="form-check-label" for="withdrawTypeReal">{{$t("nav.realMoney")}}</label>
                            </div>
                            <div class="form-check form-check-inline col-form-label">
                                <input class="form-check-input" type="radio" name="withdrawType" v-model="withdrawType" :value="GAME_TYPE.esports" id="withdrawTypeEsports">
                                <label class="form-check-label" for="withdrawTypeEsports">{{$t("nav.esportsMoney")}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column flex-sm-row mb-3">
                        <div class="col-12 col-sm-3">
                            <label for="withdrawPwd" class="col-form-label">{{$t("personal.withdrawPwd")}}：</label>
                        </div>
                        <div class="col">
                            <input type="password" class="form-control bg-light" v-model="withdrawPwd" id="withdrawPwd" :placeholder="$t('personal.withdrawPwd')" name="withdrawPwd">
                        </div>
                    </div>
                    <button type="button" @click="submitEvent('withdraw')" class="btn btn-primary text-light">{{$t("personal.submit")}}</button>
                </div>
                <!-- 资金渠道 -->
                <div v-if="currModal==$t('personal.updateSource')" class="d-flex flex-column flex-grow-1 h-30rem w-50rem overflow-y-scroll">
                    <div class="row">
                        <div class="col-12 col-sm-4 mb-3 mb-md-0">
                            <div class="card border-primary overflow-hidden">
                                <div class="card-header d-flex justify-content-between text-primary bg-light">
                                    <div>
                                        <i class="bi bi-alipay me-1"></i>
                                        <span>{{$t("personal.alipay")}}</span>
                                    </div>
                                    <i class="bi" :class="[isShowAddAlipay?'bi-dash-square-fill':'bi-plus-square-fill']" role="button" @click="isShowAddAlipay=isShowAddAlipay?false:true"></i>
                                </div>
                                <div class="card-body p-0">
                                    <ul class="list-group list-group-flush">
                                        <li v-for="i in sourceAlipayList" :key="i" class="list-group-item d-flex justify-content-between align-items-center list-group-item-primary">
                                            <div class="flex-grow-1 w-0 pe-1">
                                                <div>
                                                    <span class="fw-bold">{{$t("personal.name")}}：</span>
                                                    <span>{{i.name}}</span>
                                                </div>
                                                <div>
                                                    <span class="fw-bold">{{$t("personal.account")}}：</span>
                                                    <span>{{i.user}}</span>
                                                </div>
                                            </div>
                                            <span class="badge bg-danger rounded fs-6 text-light" role="button" @click="sourceEvent('del',i.id,FUNDS_WITHDRAW_TYPE.alipay)">
                                                <i class="bi bi-trash-fill"></i>
                                            </span>
                                        </li>
                                        <li v-show="isShowAddAlipay" class="list-group-item list-group-item-primary">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <form class="flex-grow-1 w-0 pe-3">
                                                    <div class="d-flex mb-1">
                                                        <div class="col-4 pe-0">
                                                            <label for="addAlipayName" class="fw-bold col-form-label col-form-label-sm">{{$t("personal.name")}}：</label>
                                                        </div>
                                                        <div class="col">
                                                            <input type="text" id="addAlipayName" v-model="addAlipayName" class="form-control form-control-sm" :placeholder="$t('personal.name')" name="addAlipayName">
                                                        </div>
                                                    </div>
                                                    <div class="d-flex">
                                                        <div class="col-4 pe-0">
                                                            <label for="addAlipayAccount" class="fw-bold col-form-label col-form-label-sm">{{$t("personal.account")}}：</label>
                                                        </div>
                                                        <div class="col">
                                                            <input type="text" id="addAlipayAccount" v-model="addAlipayAccount" class="form-control form-control-sm" :placeholder="$t('personal.account')" name="addAlipayAccount">
                                                        </div>
                                                    </div>
                                                </form>
                                                <span class="badge bg-success rounded fs-6" role="button" @click="sourceEvent('add','',FUNDS_WITHDRAW_TYPE.alipay)">
                                                    <i class="bi bi-save"></i>
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4 mb-3 mb-sm-0">
                            <div class="card border-dark overflow-hidden">
                                <div class="card-header d-flex justify-content-between text-dark bg-light">
                                    <div>
                                        <i class="bi bi-credit-card me-1"></i>
                                        <span>{{$t("personal.unionPay")}}</span>
                                    </div>
                                    <i class="bi" :class="[isShowAddBank?'bi-dash-square-fill':'bi-plus-square-fill']" role="button" @click="isShowAddBank=isShowAddBank?false:true"></i>
                                </div>
                                <div class="card-body p-0">
                                    <ul class="list-group list-group-flush">
                                        <li v-for="i in sourceBankList" :key="i" class="list-group-item d-flex justify-content-between align-items-center list-group-item-dark">
                                            <div class="flex-grow-1 w-0 pe-1">
                                                <div>
                                                    <span class="fw-bold">{{$t("personal.name")}}：</span>
                                                    <span>{{i.name}}</span>
                                                </div>
                                                <div>
                                                    <span class="fw-bold">{{$t("personal.account")}}：</span>
                                                    <span>{{i.user}}</span>
                                                </div>
                                                <div>
                                                    <span class="fw-bold">{{$t("personal.bank")}}：</span>
                                                    <span>{{i.bankname}}</span>
                                                </div>
                                            </div>
                                            <span class="badge bg-danger rounded fs-6 text-light" role="button" @click="sourceEvent('del',i.id,FUNDS_WITHDRAW_TYPE.bank)">
                                                <i class="bi bi-trash-fill"></i>
                                            </span>
                                        </li>
                                        <li v-show="isShowAddBank" class="list-group-item list-group-item-dark">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <form class="flex-grow-1 w-0 pe-3">
                                                    <div class="d-flex mb-1">
                                                        <div class="col-4 pe-0">
                                                            <label for="addBankName" class="fw-bold col-form-label col-form-label-sm">{{$t("personal.name")}}：</label>
                                                        </div>
                                                        <div class="col">
                                                            <input type="text" id="addBankName" v-model="addBankName" class="form-control form-control-sm" :placeholder="$t('personal.name')" name="addBankName">
                                                        </div>
                                                    </div>
                                                    <div class="d-flex mb-1">
                                                        <div class="col-4 pe-0">
                                                            <label for="addBankAccount" class="fw-bold col-form-label col-form-label-sm">{{$t("personal.account")}}：</label>
                                                        </div>
                                                        <div class="col">
                                                            <input type="text" id="addBankAccount" v-model="addBankAccount" class="form-control form-control-sm" :placeholder="$t('personal.account')" name="addBankAccount">
                                                        </div>
                                                    </div>
                                                    <div class="d-flex">
                                                        <div class="col-4 pe-0">
                                                            <label for="addBankBank" class="fw-bold col-form-label col-form-label-sm">{{$t("personal.bank")}}：</label>
                                                        </div>
                                                        <div class="col">
                                                            <input type="text" id="addBankBank" v-model="addBankBank" class="form-control form-control-sm" :placeholder="$t('personal.bank')" name="addBankBank">
                                                        </div>
                                                    </div>
                                                </form>
                                                <span class="badge bg-success rounded fs-6" role="button" @click="sourceEvent('add','',FUNDS_WITHDRAW_TYPE.bank)">
                                                    <i class="bi bi-save"></i>
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="card border-info overflow-hidden">
                                <div class="card-header d-flex justify-content-between text-info bg-light">
                                    <div>
                                        <i class="bi bi-currency-bitcoin me-1"></i>
                                        <span>{{$t("personal.virtualCurrency")}}</span>
                                    </div>
                                    <i class="bi" :class="[isShowAddCoin?'bi-dash-square-fill':'bi-plus-square-fill']" role="button" @click="isShowAddCoin=isShowAddCoin?false:true"></i>
                                </div>
                                <div class="card-body p-0">
                                    <ul class="list-group list-group-flush">
                                        <li v-for="i in sourceCoinList" :key="i" class="list-group-item d-flex justify-content-between align-items-center list-group-item-info">
                                            <div class="flex-grow-1 w-0 pe-1">
                                                <div>
                                                    <span class="fw-bold">{{$t("personal.currency")}}：</span>
                                                    <span>{{i.name}}</span>
                                                </div>
                                                <div>
                                                    <span class="fw-bold">{{$t("personal.walletAddress")}}：</span>
                                                    <span>{{i.url}}</span>
                                                </div>
                                            </div>
                                            <span class="badge bg-danger rounded fs-6 text-light" role="button" @click="sourceEvent('del',i.id,FUNDS_WITHDRAW_TYPE.virtual)">
                                                <i class="bi bi-trash-fill"></i>
                                            </span>
                                        </li>
                                        <li v-show="isShowAddCoin" class="list-group-item list-group-item-primary">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <form class="flex-grow-1 w-0 pe-3">
                                                    <div class="d-flex mb-1">
                                                        <div class="col-4 pe-0">
                                                            <label for="addVirtualCurrency" class="fw-bold col-form-label col-form-label-sm">{{$t("personal.currency")}}：</label>
                                                        </div>
                                                        <div class="col">
                                                            <input type="text" id="addVirtualCurrency" v-model="addVirtualCurrency" class="form-control form-control-sm" :placeholder="$t('personal.currency')" name="addVirtualCurrency">
                                                        </div>
                                                    </div>
                                                    <div class="d-flex">
                                                        <div class="col-4 pe-0">
                                                            <label for="addVirtualUrl" class="fw-bold col-form-label col-form-label-sm">{{$t("personal.walletAddress")}}：</label>
                                                        </div>
                                                        <div class="col">
                                                            <input type="text" id="addVirtualUrl" v-model="addVirtualUrl" class="form-control form-control-sm" :placeholder="$t('personal.walletAddress')" name="addVirtualUrl">
                                                        </div>
                                                    </div>
                                                </form>
                                                <span class="badge bg-success rounded fs-6" role="button" @click="sourceEvent('add','',FUNDS_WITHDRAW_TYPE.virtual)">
                                                    <i class="bi bi-save"></i>
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 修改密码 -->
                <form v-if="currModal==$t('common.safeSetting')" class="text-light text-nowrap">
                    <div class="p-3 p-sm-4 border border-1 rounded">
                        <div class="d-flex flex-column flex-sm-row mb-3">
                            <div class="col-12 col-sm-3">
                                <label class="col-form-label">{{$t("personal.pwdType")}}：</label>
                            </div>
                            <div class="col">
                                <div class="form-check form-check-inline col-form-label">
                                    <input class="form-check-input" type="radio" v-model="currPwdType" name="pwdType" :value="PWD_TYPE.login" id="pwdLogin">
                                    <label class="form-check-label" for="pwdLogin">{{$t("personal.loginPwd")}}</label>
                                </div>
                                <div class="form-check form-check-inline col-form-label">
                                    <input class="form-check-input" type="radio" v-model="currPwdType" name="pwdType" :value="PWD_TYPE.withdraw" id="pwdWithdraw">
                                    <label class="form-check-label" for="pwdWithdraw">{{$t("personal.withdrawPwd")}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-column flex-sm-row mb-3">
                            <div class="col-12 col-sm-3">
                                <label for="oldPwd" class="col-form-label">{{$t("personal.oldPwd")}}：</label>
                            </div>
                            <div class="col">
                                <input type="password" class="form-control bg-light" v-model="oldPwd" id="oldPwd" :placeholder="$t('personal.oldPwd')" name="oldPwd">
                            </div>
                        </div>
                        <div class="d-flex flex-column flex-sm-row mb-3">
                            <div class="col-12 col-sm-3">
                                <label for="newPwd" class="col-form-label">{{$t("personal.newPwd")}}：</label>
                            </div>
                            <div class="col">
                                <input type="password" class="form-control bg-light" v-model="newPwd" id="newPwd" :placeholder="$t('personal.newPwd')" name="newPwd">
                            </div>
                        </div>
                        <div class="d-flex flex-column flex-sm-row mb-3">
                            <div class="col-12 col-sm-3">
                                <label for="againPwd" class="col-form-label">{{$t("personal.againPwd")}}：</label>
                            </div>
                            <div class="col">
                                <input type="password" class="form-control bg-light" v-model="againPwd" id="againPwd" :placeholder="$t('personal.againPwd')" name="againPwd">
                            </div>
                        </div>
                        <button type="button" @click="submitEvent('updatePwd')" class="btn btn-primary text-light">{{$t("personal.submit")}}</button>
                    </div>
                </form>
                <!-- 会员推广 -->
                <div v-if="currModal==$t('common.vipShare')" class="col-12">
                    <div class="d-flex flex-column justify-content-center">
                        <vue-qr class="img-thumbnail bg-transparent rounded" :margin="10" colorDark="#0dcaf0" :text="qrText"></vue-qr>
                        <button type="button" class="btn btn-link" @click="copy(qrText)">复制到剪贴板</button>
                    </div>
                </div>
                <!-- 问题反馈 -->
                <div v-if="currModal==$t('common.questionFeedBack')" class="row flex-grow-1 px-0">
                    <div class="d-flex flex-column flex-grow-1">
                        <div class="overflow-y-scroll flex-grow-1 h-20rem w-30rem" v-scroll-down>
                            <div v-for="i in problemList" :key="i">
                                <div class="d-flex justify-content-end mb-3">
                                    <div class="col-10 col-sm-8">
                                        <div class="list-group">
                                            <div class="list-group-item list-group-item-primary">
                                                <div class="d-flex w-100 justify-content-between">
                                                    <h5 class="mb-2">{{i.title}}</h5>
                                                    <small>{{i.qtime}}</small>
                                                </div>
                                                <div>{{i.question}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="fs-2 ms-2">
                                        <i class="bi bi-person-fill"></i>
                                    </div>
                                </div>
                                <div v-if="i.state==1" class="d-flex mb-3">
                                    <div class="fs-2 me-2">
                                        <i class="bi bi-person"></i>
                                    </div>
                                    <div class="col-10 col-sm-8">
                                        <div class="list-group">
                                            <div class="list-group-item">
                                                <div class="d-flex w-100 justify-content-between">
                                                    <h5 class="mb-2">{{i.title}}</h5>
                                                    <small>{{i.atime}}</small>
                                                </div>
                                                <div>{{i.answer}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form class="d-flex flex-column rounded">
                            <div class="mb-1">
                                <input class="form-control bg-light" type="text" v-model="txtProblemTitle" :placeholder="$t('personal.enterTitle')">
                            </div>
                            <div class="bg-white rounded-top">
                                <textarea class="form-control form-control-nofocus border-0 textarea-noresize bg-light" v-model="txtProblemContent" rows="2" :placeholder="$t('personal.enterProblem')"></textarea>
                            </div>
                            <div class="d-flex justify-content-end bg-light rounded-bottom">
                                <button type="button" class="btn btn-sm btn-outline-success m-2" @click="submitProblem">{{$t("personal.send")}}</button>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- 账户记录 -->
                <div v-if="currModal==$t('common.userRecords')" class="text-light h-30rem w-50rem d-flex flex-column">
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                        <button class="nav-link py-1 active" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button">{{$t("personal.submitLog")}}</button>
                        <button class="nav-link py-1" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button">{{$t("personal.rechargeLog")}}</button>
                        <button class="nav-link py-1" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button">{{$t("personal.withdrawLog")}}</button>
                    </div>
                    <div class="flex-grow-1 tab-content" id="nav-tabContent">
                        <!-- 提交记录 -->
                        <div class="tab-pane fade show active h-100" id="nav-contact" tabindex="0">
                            <div class="h-100 row flex-column px-0">
                                <form class="row mb-3 mx-0 px-0 align-items-end">
                                    <div class="col-6 col-sm-3">
                                        <div class="col">
                                            <label for="submitLogStartDate" class="col-form-label-sm">{{$t("personal.startDate")}}(06:00AM)：</label>
                                        </div>
                                        <div class="col">
                                            <input type="date" class="form-control form-control-sm bg-light" @change="getLog('submit')" v-model="submitLogStartDate" id="submitLogStartDate" :max="maxDate" :placeholder="$t('personal.startDate')" name="submitLogStartDate">
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-3">
                                        <div class="col">
                                            <label for="submitLogEndDate" class="col-form-label-sm">{{$t("personal.endDate")}}(06:00AM)：</label>
                                        </div>
                                        <div class="col">
                                            <input type="date" class="form-control form-control-sm bg-light" @change="getLog('submit')" v-model="submitLogEndDate" id="submitLogEndDate" :max="maxDate" :placeholder="$t('personal.endDate')" name="submitLogEndDate">
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-3">
                                        <div class="col">
                                            <label class="col-form-label-sm">{{$t("personal.gameType")}}：</label>
                                        </div>
                                        <div class="col">
                                            <select class="form-select form-select-sm bg-light" v-model="submitLogGameType" @change="getLog('submit')">
                                                <option :value="GAME_ID.bac">{{$t("common.bac")}}</option>
                                                <option :value="GAME_ID.cor">{{$t("common.cor")}}</option>
                                                <option :value="GAME_ID.dice">{{$t("common.dice")}}</option>
                                                <option :value="GAME_ID.rou">{{$t("common.rou")}}</option>
                                                <option :value="GAME_ID.longfong">{{$t("common.longfong")}}</option>
                                                <option :value="GAME_ID.coin">{{$t("common.coin")}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </form>
                                <div class="fw-bold d-none d-sm-flex text-center">
                                    <div class="col">{{$t("personal.date")}}</div>
                                    <div class="col">{{$t("personal.room")}}</div>
                                    <div class="col-auto">{{$t("personal.media")}}</div>
                                    <div class="col">{{$t("personal.final")}}</div>
                                    <div class="col">{{$t("personal.deposit")}}</div>
                                    <div class="col">{{$t("personal.result")}}</div>
                                    <div class="col-5">{{$t("personal.report")}}</div>
                                </div>
                                <div class="flex-grow-1 h-0 overflow-y-scroll scroll-none pb-sm-1 small position-relative" :class="{'scroll-none':submitLog.length<=0}">
                                    <div v-show="submitLog.length<=0&&!isSearch" class="text-center text-muted fs-6 py-3">{{$t("personal.noSubmitLog")}}</div>
                                    <div v-if="isSearch" class="position-absolute top-0 bottom-0 w-100 start-0 d-flex flex-column justify-content-center">
                                        <div class="spinner-border text-info mx-auto" role="status"></div>
                                        <div class="text-white text-center mt-2">{{$t("common.load")}}</div>
                                    </div>
                                    <div v-if="!isSearch">
                                        <div v-for="i in submitLog" :key="i" class="list-item d-flex flex-wrap border-xs border-top-sm border-start-sm border-end-sm rounded-xs p-1 p-sm-0 mb-2 mb-sm-0">
                                            <!-- 日期 -->
                                            <div class="col-5 col-sm-0 flex-sm-fill px-1 py-sm-1 d-flex flex-sm-column justify-content-sm-center">
                                                <div class="fw-bold d-sm-none pe-1 pe-sm-0">{{$t("personal.date")}}</div>
                                                <div class="d-sm-flex flex-sm-column flex-grow-1 flex-sm-grow-0 py-sm-1 text-end text-sm-center">
                                                    <span class="pe-1 pe-sm-0">{{i.date.substring(0,i.date.indexOf(' '))}}</span>
                                                    <span>{{i.date.substring(i.date.indexOf(' ')+1)}}</span>
                                                </div>
                                            </div>
                                            <!-- 房间 -->
                                            <div class="col-5 col-sm-0 flex-sm-fill px-1 py-sm-1 d-flex flex-sm-column justify-content-sm-center">
                                                <div class="fw-bold d-sm-none pe-1 pe-sm-0">{{$t("personal.room")}}</div>
                                                <div class="flex-grow-1 flex-sm-grow-0 d-flex justify-content-sm-center">
                                                    <div class="d-flex flex-sm-column justify-content-end col-12">
                                                        <div class="d-flex justify-content-end justify-content-sm-center">
                                                            <div>{{i.room}}</div>
                                                            <div class="pe-1 pe-sm-0">{{i.table}}</div>
                                                        </div>
                                                        <div class="d-flex justify-content-end justify-content-sm-center">
                                                            <div>{{i.boot}}-</div>
                                                            <div>{{i.innings}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- 设备 -->
                                            <div class="col-2 col-sm-auto px-1 py-sm-1 d-flex flex-sm-column justify-content-sm-center">
                                                <div class="fw-bold d-sm-none pe-1 pe-sm-0">{{$t("personal.media")}}</div>
                                                <div class="flex-grow-1 flex-sm-grow-0 px-sm-1 py-sm-1 text-end text-sm-center">
                                                    <i class="bi bi-display" v-if="i.source.indexOf('h5_web')!=-1"></i>
                                                    <i class="bi bi-phone" v-else-if="i.source.indexOf('h5_phone')!=-1"></i>
                                                    <i class="bi bi-question" v-else></i>
                                                </div>
                                            </div>
                                            <!-- 输赢 -->
                                            <div class="col-3 col-sm-0 flex-sm-fill px-1 py-sm-1 d-flex flex-sm-column justify-content-sm-center">
                                                <div class="fw-bold d-sm-none pe-1 pe-sm-0">{{$t("personal.final")}}</div>
                                                <div class="flex-grow-1 flex-sm-grow-0 py-sm-1 text-start text-sm-center" :style="{color:parseFloat(i.win)<0?COLOR.blue:parseFloat(i.win)>0?COLOR.red:''}">{{parseFloat(i.win)}}</div>
                                            </div>
                                            <!-- 余额 -->
                                            <div class="col-3 col-sm-0 flex-sm-fill px-1 py-sm-1 d-flex flex-sm-column justify-content-sm-center">
                                                <div class="fw-bold d-sm-none pe-1 pe-sm-0">{{$t("personal.deposit")}}</div>
                                                <div class="flex-grow-1 flex-sm-grow-0 py-sm-1 text-start text-sm-center">{{parseFloat(i.realmoney)}}</div>
                                            </div>
                                            <!-- 结果 -->
                                            <div class="col-6 col-sm-0 flex-sm-fill px-1 py-sm-1 d-flex flex-sm-column justify-content-sm-center">
                                                <div class="fw-bold d-sm-none pe-1 pe-sm-0">{{$t("personal.result")}}</div>
                                                <div class="flex-grow-1 flex-sm-grow-0 py-sm-1 text-start text-sm-center" v-html="setResultHtml(i.result)"></div>
                                            </div>
                                            <!-- 详情 -->
                                            <div class="col-12 col-sm-5 px-1 py-sm-1 d-flex flex-sm-column justify-content-sm-center">
                                                <div class="fw-bold d-sm-none pe-1 pe-sm-0">{{$t("personal.report")}}</div>
                                                <div class="flex-grow-1 flex-sm-grow-0 d-flex flex-sm-column py-sm-1 text-sm-center justify-content-between" v-html="setReportHtml(i.detail,i.game)"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-evenly text-light text-opacity-50 small py-2">
                                    <div>
                                        <span>{{$t("personal.total")}}：</span>
                                        <span>{{submitLogTotal}}</span>
                                    </div>
                                    <div>
                                        <span>{{$t("personal.valid")}}：</span>
                                        <span>{{submitLogValid}}</span>
                                    </div>
                                    <div>
                                        <span>{{$t("personal.final")}}：</span>
                                        <span>{{submitLogWin}}</span>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center">
                                    <pagination :count-page="countPage" @change-page="changePage"></pagination>
                                </div>
                            </div>
                        </div>
                        <!-- 充值记录 -->
                        <div class="tab-pane fade h-100" id="nav-home" tabindex="0">
                            <div class="h-100 row flex-column px-0">
                                <form class="row mb-3 mx-0 px-0">
                                    <div class="col-6 col-sm-3">
                                        <div class="col">
                                            <label for="rechargeLogStartDate" class="col-form-label-sm">{{$t("personal.startDate")}}(06:00AM)：</label>
                                        </div>
                                        <div class="col">
                                            <input type="date" class="form-control form-control-sm bg-light" @change="getLog('recharge')" v-model="rechargeStartDate" :max="maxDate" id="rechargeLogStartDate" :placeholder="$t('personal.startDate')" name="rechargeLogStartDate">
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-3">
                                        <div class="col">
                                            <label for="rechargeLogEndDate" class="col-form-label-sm">{{$t("personal.endDate")}}(06:00AM)：</label>
                                        </div>
                                        <div class="col">
                                            <input type="date" class="form-control form-control-sm bg-light" @change="getLog('recharge')" v-model="rechargeEndDate" :max="maxDate" id="rechargeLogEndDate" :placeholder="$t('personal.endDate')" name="rechargeLogEndDate">
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-3">
                                        <div class="col">
                                            <label class="col-form-label-sm">{{$t("personal.payment")}}：</label>
                                        </div>
                                        <div class="col">
                                            <select class="form-select form-select-sm bg-light" @change="getLog('recharge')" v-model="rechargeLogPayment">
                                                <option value="0">{{$t("personal.all")}}</option>
                                                <option v-for="i in rechargeFundsType" :key="i" :value="i.id">{{getLogType("recharge",i.id)}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-3">
                                        <div class="col">
                                            <label class="col-form-label-sm">{{$t("personal.rechargeType")}}：</label>
                                        </div>
                                        <div class="col">
                                            <select class="form-select form-select-sm bg-light" @change="getLog('recharge')" v-model="rechargeLogType">
                                                <option value="0">{{$t("personal.all")}}</option>
                                                <option value="1">{{$t("nav.realMoney")}}</option>
                                                <option value="2">{{$t("nav.esportsMoney")}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </form>
                                <div class="fw-bold d-none d-sm-flex text-center">
                                    <div class="col-sm-2">{{$t("personal.date")}}</div>
                                    <div class="col-sm-2">{{$t("personal.money")}}</div>
                                    <div class="col-sm-2">{{$t("personal.currency")}}</div>
                                    <div class="col-sm-2">{{$t("personal.payment")}}</div>
                                    <div class="col-sm-2">{{$t("personal.rechargeType")}}</div>
                                    <div class="col-sm-2">{{$t("personal.rechargeState")}}</div>
                                </div>
                                <div class="flex-grow-1 h-0 overflow-y-scroll scroll-none pb-sm-1" :class="{'scroll-none':rechargeLog.length<=0}">
                                    <div v-show="rechargeLog.length<=0&&!isSearch" class="text-center text-muted fs-6 py-3">{{$t("personal.noRechargeLog")}}</div>
                                    <div v-if="isSearch" class="position-absolute top-0 bottom-0 w-100 start-0 d-flex flex-column justify-content-center">
                                        <div class="spinner-border text-info mx-auto" role="status"></div>
                                        <div class="text-white text-center mt-2">{{$t("common.load")}}</div>
                                    </div>
                                    <div v-if="!isSearch">
                                        <div v-for="i in rechargeLog" :key="i" class="list-item d-flex flex-wrap border-xs border-top-sm border-start-sm border-end-sm rounded-xs p-1 p-sm-0 mb-2 mb-sm-0">
                                            <div class="col-4 col-sm-2 px-1 py-sm-1">
                                                <div class="fw-bold d-sm-none">{{$t("personal.date")}}</div>
                                                <div class="py-1 text-sm-center">{{i.date}}</div>
                                            </div>
                                            <div class="col-4 col-sm-2 px-1 py-sm-1">
                                                <div class="fw-bold d-sm-none">{{$t("personal.money")}}</div>
                                                <div class="py-1 text-sm-center">{{i.money}}</div>
                                            </div>
                                            <div class="col-4 col-sm-2 px-1 py-sm-1">
                                                <div class="fw-bold d-sm-none">{{$t("personal.currency")}}</div>
                                                <div class="py-1 text-sm-center">{{i.coin}}</div>
                                            </div>
                                            <div class="col-4 col-sm-2 px-1 py-sm-1">
                                                <div class="fw-bold d-sm-none">{{$t("personal.payment")}}</div>
                                                <div class="py-1 text-sm-center">{{getLogType('recharge',i.way)}}</div>
                                            </div>
                                            <div class="col-4 col-sm-2 px-1 py-sm-1">
                                                <div class="fw-bold d-sm-none">{{$t("personal.rechargeType")}}</div>
                                                <div class="py-1 text-sm-center">{{i.type==GAME_TYPE.real?$t("personal.real"):(i.type==GAME_TYPE.esports?$t("personal.esports"):"")}}</div>
                                            </div>
                                            <div class="col-4 col-sm-2 px-1 py-sm-1">
                                                <div class="fw-bold d-sm-none">{{$t("personal.rechargeState")}}</div>
                                                <div class="py-1 text-sm-center">{{getLogState(i.state)}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 提现记录 -->
                        <div class="tab-pane fade h-100" id="nav-profile" tabindex="0">
                            <div class="h-100 row flex-column px-0">
                                <form class="row mb-3 mx-0 px-0">
                                    <div class="col-6 col-sm-3">
                                        <div class="col">
                                            <label for="withdrawLogStartDate" class="col-form-label-sm">{{$t("personal.startDate")}}(06:00AM)：</label>
                                        </div>
                                        <div class="col">
                                            <input type="date" class="form-control form-control-sm bg-light" @change="getLog('withdraw')" v-model="withdrawStartDate" :max="maxDate" id="withdrawLogStartDate" :placeholder="$t('personal.startDate')" name="withdrawLogStartDate">
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-3">
                                        <div class="col">
                                            <label for="withdrawLogEndDate" class="col-form-label-sm">{{$t("personal.endDate")}}(06:00AM)：</label>
                                        </div>
                                        <div class="col">
                                            <input type="date" class="form-control form-control-sm bg-light" @change="getLog('withdraw')" v-model="withdrawEndDate" :max="maxDate" id="withdrawLogEndDate" :placeholder="$t('personal.endDate')" name="withdrawLogEndDate">
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-3">
                                        <div class="col">
                                            <label class="col-form-label-sm">{{$t('personal.withdrawState')}}：</label>
                                        </div>
                                        <div class="col">
                                            <select class="form-select form-select-sm bg-light" v-model="withdrawLogState" @change="getLog('withdraw')">
                                                <option value="-1">{{$t("personal.all")}}</option>
                                                <option v-for="i in Object.values(LOG_STATE)" :key="i" :value="i">{{getLogState(i)}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-3">
                                        <div class="col">
                                            <label class="col-form-label-sm">{{$t('personal.withdrawType')}}：</label>
                                        </div>
                                        <div class="col">
                                            <select class="form-select form-select-sm bg-light" v-model="withdrawLogType" @change="getLog('withdraw')">
                                                <option value="0">{{$t("personal.all")}}</option>
                                                <option value="1">{{$t("personal.real")}}</option>
                                                <option value="2">{{$t("personal.esports")}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </form>
                                <div class="fw-bold d-none d-sm-flex text-center">
                                    <div class="col">{{$t("personal.date")}}</div>
                                    <div class="col">{{$t("personal.money")}}</div>
                                    <div class="col">{{$t("personal.accepted")}}</div>
                                    <div class="col">{{$t("personal.withdrawType")}}</div>
                                    <div class="col">{{$t("personal.withdrawState")}}</div>
                                </div>
                                <div class="flex-grow-1 h-0 overflow-y-scroll scroll-none pb-sm-1" :class="{'scroll-none':withdrawLog.length<=0}">
                                    <div v-show="withdrawLog.length<=0&&!isSearch" class="text-center text-muted fs-6 py-3">{{$t("personal.noWithdrawLog")}}</div>
                                    <div v-if="isSearch" class="position-absolute top-0 bottom-0 w-100 start-0 d-flex flex-column justify-content-center">
                                        <div class="spinner-border text-info mx-auto" role="status"></div>
                                        <div class="text-white text-center mt-2">{{$t("common.load")}}</div>
                                    </div>
                                    <div v-if="!isSearch">
                                        <div v-for="i in withdrawLog" :key="i" class="list-item d-flex flex-wrap border-xs border-top-sm border-start-sm border-end-sm rounded-xs p-1 p-sm-0 mb-2 mb-sm-0">
                                            <div class="col-4 col-sm-0 flex-sm-grow-1 flex-sm-shrink-0 px-1 py-sm-1">
                                                <div class="fw-bold d-sm-none">{{$t("personal.date")}}</div>
                                                <div class="py-1 text-sm-center">{{i.date}}</div>
                                            </div>
                                            <div class="col-4 col-sm-0 flex-sm-grow-1 flex-sm-shrink-0 px-1 py-sm-1">
                                                <div class="fw-bold d-sm-none">{{$t("personal.money")}}</div>
                                                <div class="py-1 text-sm-center">{{i.money}}</div>
                                            </div>
                                            <div class="col-4 col-sm-0 flex-sm-grow-1 flex-sm-shrink-0 px-1 py-sm-1">
                                                <div class="fw-bold d-sm-none">{{$t("personal.accepted")}}</div>
                                                <div class="py-1 text-sm-center">{{getLogType('withdraw',i.way)}}</div>
                                            </div>
                                            <div class="col-4 col-sm-0 flex-sm-grow-1 flex-sm-shrink-0 px-1 py-sm-1">
                                                <div class="fw-bold d-sm-none">{{$t("personal.withdrawType")}}</div>
                                                <div class="py-1 text-sm-center">{{i.type==GAME_TYPE.real?$t("personal.real"):(i.type==GAME_TYPE.esports?$t("personal.esports"):"")}}</div>
                                            </div>
                                            <div class="col-4 col-sm-0 flex-sm-grow-1 flex-sm-shrink-0 px-1 py-sm-1">
                                                <div class="fw-bold d-sm-none">{{$t("personal.withdrawState")}}</div>
                                                <div class="py-1 text-sm-center">{{getLogState(i.state)}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {COMMAND,GAME_TYPE,FUNDS_RECHARGE_TYPE,RECHARGE_RESULT,FUNDS_WITHDRAW_TYPE,WITHDRAW_RESULT,PWD_TYPE,UPDATE_PWD_RESULT,LAYER_TYPE,SET_SOURCE_RESULT,GAME_ID,COLOR,LOG_STATE,MUTATION_TYPE} from "@/assets/js/config.js"
import { useLayer } from '@/assets/js/useLayer';
import { getDeviceType,formatDate,copy } from '@/assets/js/common.js'
import pagination from "@/components/public/pagination.vue";
//使用二维码插件，安装：npm install vue-qr --save，官网：https://github.com/Binaryify/vue-qr#readme
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
    export default{
        components: { vueQr,pagination },
        directives: {
            'scroll-down': {
                updated(elem){
                    elem.scrollTop = elem.scrollHeight;//将滚动条自动置于底部
                }
            },
        },
        props:{
            currModal:{
                type:String,
                default:""
            },//当前要显示的模态框
            rechargeFundsType:{
                type:Array,
                default:[]
            },//充值支付方式列表
            currencyList:{
                type:Array,
                default:[]
            },//充值币种列表
            rechargeResult:{
                type:Object,
                default:{}
            },//充值返回对象
            withdrawFundsType:{
                type:Array,
                default:[]
            },//提现提现方式列表
            sourceAlipayList:{
                type:Array,
                default:[]
            },//支付宝资金渠道
            sourceBankList:{
                type:Array,
                default:[]
            },//银联资金渠道
            sourceCoinList:{
                type:Array,
                default:[]
            },//虚拟币资金渠道
            withdrawLimit:{
                type:Object,
                default:{
                    min:0,
                    max:0
                }
            },//提现限额
            withdrawResult:{
                type:Object,
                default:{}
            },//提现返回对象
            updatepwdResult:{
                type:Object,
                default:{}
            },//修改密码返回对象
            qrText:{
                type:String,
                default:""
            },//分享二维码文本
            problemList:{
                type:Array,
                default:[]
            },//问题反馈列表
            addquestionResult:{
                type:Object,
                default:{}
            },//问题反馈返回对象
            setuserchannelResult:{
                type:Object,
                default:{}
            },//操作用户资金方式返回对象
            deluserchannelResult:{
                type:Object,
                default:{}
            },//删除用户资金方式返回对象
            getbetlogData:{
                type:Object,
                default:{}
            },//获取提交记录返回数据
            getrechargeData:{
                type:Object,
                default:{}
            },//获取充值记录返回数据
            getwithdrawData:{
                type:Object,
                default:{}
            },//获取提现记录返回数据
        },
        data(){
            return {
                FUNDS_RECHARGE_TYPE,
                GAME_TYPE,
                FUNDS_WITHDRAW_TYPE,
                PWD_TYPE,
                GAME_ID,
                COLOR,
                LOG_STATE,
                rechargeLimit:{
                    min:0,
                    max:0
                },//充值币种限额
                rechargeType:GAME_TYPE.real,//选中的充值类型
                isShowRechargeForm:true,//是否显示充值表单
                rechargeAmount:'',//充值金额
                payment:"",//选中的支付方式
                rechargeCurrency:"",//选中的充值币种
                rechargeCode:"",//充值验证码
                isShowQrcode:true,//是否显示充值二维码
                rechargeQrcode:"",//充值二维码
                isShowBankInfo:false,//是否显示充值成功的银行信息
                rechargeSuccBankName:"",//充值成功银行卡姓名
                rechargeSuccBankAccount:"",//充值成功银行卡账号
                rechargeSuccBankBank:"",//充值成功银行卡开户行
                withdrawal:"",//选中的提现方式
                withdrawAmount:"",//提现金额
                accepted:0,//选中的收款方
                withdrawType:GAME_TYPE.real,//选中的提现类型
                withdrawPwd:"",//提现密码
                acceptedList:[],//收款方列表
                currPwdType:"",//选中的密码类型
                oldPwd:"",//原密码
                newPwd:"",//新密码
                againPwd:"",//确认密码
                txtProblemTitle:"",//建议反馈标题
                txtProblemContent:"",//建议反馈内容
                isShowAddAlipay:false,//是否显示添加支付宝资金渠道
                isShowAddBank:false,//是否显示添加银联资金渠道
                isShowAddCoin:false,//是否显示添加区块链资金渠道
                addAlipayName:"",//要新增的支付宝姓名
                addAlipayAccount:"",//要新增的支付宝账号
                addBankName:"",//要新增的银联姓名
                addBankAccount:"",//要新增的银联账号
                addBankBank:"",//要新增的银联开户行
                addVirtualCurrency:"",//要新增的虚拟币币种
                addVirtualCurrencyName:"",//要新增的虚拟币币种名称
                addVirtualUrl:"",//要新增的虚拟币钱包地址
                setFundsType:"",//要删除或新增的资金类型
                //充值记录
                rechargeLog:[],//充值记录列表
                rechargeStartDate:"",//充值记录开始时间
                rechargeEndDate:"",//充值记录结束时间
                maxDate:"",//最大查询日期
                rechargeLogPayment:0,//充值记录支付方式
                rechargeLogType:0,//充值记录充值类型
                //提现记录
                withdrawLog:[],//提现记录列表
                withdrawStartDate:"",//提现记录开始时间
                withdrawEndDate:"",//提现记录结束时间
                withdrawLogState:-1,//提现记录提现状态(默认全部)
                withdrawLogType:0,//提现记录提现类型
                //提交记录
                submitLog:[],//获取提交记录列表
                submitLogTotal:0,//总额
                submitLogValid:0,//有效额
                submitLogWin:0,//输赢
                submitLogStartDate:"",//提交记录开始时间
                submitLogEndDate:"",//提交记录结束时间
                submitLogGameType:GAME_ID.bac,//提交记录游戏类型
                isSearch:false,//当前是否在搜索状态，是的话需要显示加载层
                countPage:1,//总页数
                currPage:1,//当前页码
                countPage:1,//总页数
            }
        },
        computed: {
            userInfo() {
                return this.$store.state.userInfo;
            },
            videoLineList(){
                return this.$store.state.videoLineList;
            },
            currVideoLine(){
                return this.$store.state.currVideoLine;
            },
            audioSwitch(){
                return this.$store.state.audioSwitch;
            }
        },
        mounted(){
            let startDate=new Date();
            let endDate=new Date();
            if(new Date().getHours()<6){//当前时间在6点前
                //开始时间设置为昨天6点
                startDate.setDate(startDate.getDate()-1);
                startDate.setHours(6);
                startDate.setMinutes(0);
                startDate.setSeconds(0);
                //结束时间设置为今天6点
                endDate.setHours(6);
                endDate.setMinutes(0);
                endDate.setSeconds(0);
            }else{//当前时间在6点后
                //开始时间设置为今天6点
                startDate.setHours(6);
                startDate.setMinutes(0);
                startDate.setSeconds(0);
                //结束时间设置为明天6点
                endDate.setDate(endDate.getDate()+1);
                endDate.setHours(6);
                endDate.setMinutes(0);
                endDate.setSeconds(0);
            }
            this.maxDate=formatDate(endDate).split(' ')[0];//最大选择日期为当前日期
            this.rechargeStartDate=formatDate(startDate).split(' ')[0];
            this.rechargeEndDate=formatDate(endDate).split(' ')[0];
            this.withdrawStartDate=formatDate(startDate).split(' ')[0];
            this.withdrawEndDate=formatDate(endDate).split(' ')[0];
            this.submitLogStartDate= formatDate(startDate).split(' ')[0];
            this.submitLogEndDate=formatDate(endDate).split(' ')[0];
            //获取充值记录
            this.$root.wsSend(COMMAND.all_getrecharge,formatDate(startDate),formatDate(endDate),this.rechargeLogPayment,this.rechargeLogType,this.userInfo.id,this.userInfo.guid);
            //获取提现记录
            this.$root.wsSend(COMMAND.all_getwithdraw,formatDate(startDate),formatDate(endDate),this.withdrawLogType,this.withdrawLogState,this.userInfo.id,this.userInfo.guid);
            //获取提交记录
            this.$root.wsSend(COMMAND.all_getbetlog,formatDate(startDate),formatDate(endDate),this.currPage,30,this.submitLogGameType,this.userInfo.id,this.userInfo.guid);
        },
        methods:{
            /**
             * 改变资金方式
             */
            changeFundsType(tabId,id){
                let oldId;//选中项的id
                if(tabId=="recharge"){//充值
                    if(this.payment!=id){//选中了不同的资金方式
                        oldId=this.payment;
                        this.payment=id;
                    }
                    oldId!=id&&this.$root.wsSend(COMMAND.all_getcoin,id,this.userInfo.id,this.userInfo.guid);
                }else if(tabId=="withdraw"){//提现
                    if(this.withdrawal!=id){//选中了不同的资金方式
                        oldId=this.withdrawal;
                        this.withdrawal=id;
                    }
                    this.changeWithdrawAccepted(id);
                }else{
                    return;
                }
            },
            /**
             * 提交事件
             */
            submitEvent(event){
                if(event=="recharge"){//提交充值
                    if(this.rechargeAmount==""||Number.isNaN(this.rechargeAmount)||this.rechargeAmount<=0){
                        useLayer({str:this.$t("personal.rechargeAmountErr")});
                    }else if(this.rechargeAmount<this.rechargeLimit.min||this.rechargeAmount>this.rechargeLimit.max){
                        useLayer({str:this.$t("personal.rechargeRange")+this.rechargeLimit.min+"~"+this.rechargeLimit.max});
                    }else if(this.rechargeType!=GAME_TYPE.real&&this.rechargeType!=GAME_TYPE.esports){
                        useLayer({str:this.$t("personal.rechargeTypeChoose")});
                    }else if(this.payment==""){
                        useLayer({str:this.$t("personal.rechargePaymentChoose")});
                    }else if(this.rechargeCurrency==""){
                        useLayer({str:this.$t("personal.rechargeCurrencyChoose")});
                    }else{
                        this.$root.wsSend(COMMAND.all_recharge,this.rechargeAmount,this.payment,this.rechargeCurrency, this.currencyList.find(v=>v.id==this.rechargeCurrency).pid,this.rechargeType,this.$root.ip.cip,getDeviceType(),this.userInfo.id,this.userInfo.guid);
                    }
                }else if(event=="withdraw"){//提交提现
                    if(this.withdrawAmount==""||Number.isNaN(this.withdrawAmount)||this.withdrawAmount<=0){
                        useLayer({str:this.$t("personal.withdrawAmountErr")});
                    }else if(Number(this.withdrawAmount)<Number(this.withdrawLimit.min)||Number(this.withdrawAmount)>Number(this.withdrawLimit.max)){
                        useLayer({str:this.$t("personal.withdrawRange")+this.withdrawLimit.min+"~"+this.withdrawLimit.max});
                    }else if(this.withdrawal==""){
                        useLayer({str:this.$t("personal.withdrawWithdrawalChoose")});
                    }else if(this.accepted==0){
                        useLayer({str:this.$t("personal.toAddAccepted")});
                    }else if(this.withdrawType!=GAME_TYPE.real&&this.withdrawType!=GAME_TYPE.esports){
                        useLayer({str:this.$t("personal.withdrawTypeChoose")});
                    }else if(this.withdrawPwd==""){
                        useLayer({str:this.$t("personal.withdrawPwdErr")});
                    }else{
                        this.$root.wsSend(COMMAND.all_withdraw,this.withdrawAmount,this.withdrawal,this.accepted,this.withdrawPwd,this.withdrawType,this.$root.ip.cip,getDeviceType(),this.userInfo.id,this.userInfo.guid,Date.now());
                    }
                }else if(event=="updatePwd"){//提交修改密码
                    if(this.currPwdType==""){
                        useLayer({str:this.$t("personal.pwdTypeChoose")});
                    }
                    // else if(this.oldPwd==""){
                    //     useLayer({str:this.$t("personal.oldPwdEmpty")});
                    // }
                    else if(this.newPwd==""){
                        useLayer({str:this.$t("personal.newPwdEmpty")});
                    }else if(this.newPwd.length<6||this.newPwd.length>12){
                        useLayer({str:this.$t("personal.pwdLength")});
                    }else if(this.againPwd==""){
                        useLayer({str:this.$t("personal.againPwdEmpty")});
                    }
                    // else if(this.oldPwd==this.newPwd){
                    //     useLayer({str:this.$t("personal.pwdEqual")});
                    // }
                    else if(this.againPwd!=this.newPwd){
                        useLayer({str:this.$t("personal.pwdUnequal")});
                    }else{
                        //修改密码
                        this.$root.wsSend(COMMAND.all_updatepwd,this.currPwdType,this.oldPwd,this.newPwd,this.userInfo.id,this.userInfo.guid);
                    }
                }
            },
            /**
             * 改变币种，显示对应的限额
             */
            changeCurrency(id){
                let obj=this.currencyList.find(v=>v.id==id);
                this.rechargeLimit.min=obj.min;
                this.rechargeLimit.max=obj.max;
            },
            /**
             * 改变提现的提现方式
             */
            changeWithdrawAccepted(id){
                if(id==FUNDS_WITHDRAW_TYPE.alipay){
                    this.acceptedList=this.sourceAlipayList;
                }else if(id==FUNDS_WITHDRAW_TYPE.bank){
                    this.acceptedList=this.sourceBankList;
                }else if(id==FUNDS_WITHDRAW_TYPE.virtual){
                    this.acceptedList=this.sourceCoinList;
                }
                if(this.acceptedList.length>0){
                    this.accepted=this.acceptedList[0].id;
                }else {
                    this.accepted=0;
                }
            },
            /**
             * 获取提现收款方显示文本
             */
            getAcceptedTxt(obj){
                if(this.withdrawal==FUNDS_WITHDRAW_TYPE.alipay){
                    return obj.name+"："+obj.user;
                }else if(this.withdrawal==FUNDS_WITHDRAW_TYPE.bank){
                    return obj.name+"："+obj.user;
                }else if(this.withdrawal==FUNDS_WITHDRAW_TYPE.virtual){
                    return obj.name+"："+obj.url;
                }
            },
            /**
             * 提交问题反馈
             */
            submitProblem(){
                if(this.txtProblemTitle==""){
                    useLayer({str:this.$t("personal.titleEmpty")});
                }else if(this.txtProblemContent==""){
                    useLayer({str:this.$t("personal.contentEmpty")});
                }else{
                    //新增问题反馈
                    this.$root.wsSend(COMMAND.all_addquestion,this.txtProblemTitle,this.txtProblemContent,1,this.userInfo.id,this.userInfo.guid);
                }
            },
            /**
             * 资金渠道事件
             */
            sourceEvent(event,id,type){
                if(event=="del"){//删除
                    useLayer({
                        type:LAYER_TYPE.confirm,
                        str:this.$t("common.isDel"),
                        btn:[this.$t("common.yes"),this.$t("common.no")],
                        yesFn:(i)=>{
                            layer.close(i);
                            this.setFundsType=type;
                            //删除用户资金账号
                            this.$root.wsSend(COMMAND.all_deluserchannel,id,this.userInfo.id,this.userInfo.guid);
                        }
                    })
                }else if(event=="add"){//新增
                    if(type==FUNDS_WITHDRAW_TYPE.alipay){
                        if(this.addAlipayName==""){
                            useLayer({str:this.$t("personal.nameEmpty")});
                        }else if(this.addAlipayAccount==""){
                            useLayer({str:this.$t("personal.accountEmpty")});
                        }else{
                            this.$root.wsSend(COMMAND.all_setuserchannel,type,this.addAlipayName,this.addAlipayAccount,"","","",this.userInfo.id,this.userInfo.guid);
                        }
                    }else if(type==FUNDS_WITHDRAW_TYPE.bank){
                        if(this.addBankName==""){
                            useLayer({str:this.$t("personal.nameEmpty")});
                        }else if(this.addBankAccount==""){
                            useLayer({str:this.$t("personal.accountEmpty")});
                        }else if(this.addBankBank==""){
                            useLayer({str:this.$t("personal.bankEmpty")});
                        }else{
                            this.$root.wsSend(COMMAND.all_setuserchannel,type,this.addBankName,this.addBankAccount,this.addBankBank,"","",this.userInfo.id,this.userInfo.guid);
                        }
                    }else if(type==FUNDS_WITHDRAW_TYPE.virtual){
                        if(this.addVirtualCurrency==""){
                            useLayer({str:this.$t("personal.rechargeCurrencyChoose")});
                        }else if(this.addVirtualUrl==""){
                            useLayer({str:this.$t("personal.walletAddressEmpty")});
                        }else{
                            this.$root.wsSend(COMMAND.all_setuserchannel,type,"","","",this.addVirtualCurrency,this.addVirtualUrl,this.userInfo.id,this.userInfo.guid);
                        }
                    }
                }
            },
            /**
             * 获取记录
             * @param {String} type 记录类型
             */
            getLog(type){
                if(type=="submit"){//获取提交记录
                    if(Math.abs((new Date(this.submitLogStartDate)-new Date(this.submitLogEndDate))/1000/60/60/24)>45){
                        useLayer({str:this.$t("personal.dateMax")});
                        return;
                    }
                    this.isSearch=false;
                    this.$nextTick(()=>{
                        this.isSearch=true;
                        this.$root.wsSend(COMMAND.all_getbetlog,this.submitLogStartDate+" 06:00:00",this.submitLogEndDate+" 06:00:00",this.currPage,30,this.submitLogGameType,this.userInfo.id,this.userInfo.guid);
                    })
                }else if(type=="recharge"){//获取充值记录
                    if(Math.abs((new Date(this.rechargeStartDate)-new Date(this.rechargeEndDate))/1000/60/60/24)>45){
                        useLayer({str:this.$t("personal.dateMax")});
                        return;
                    }
                    this.isSearch=false;
                    this.$nextTick(()=>{
                        this.isSearch=true;
                        this.$root.wsSend(COMMAND.all_getrecharge,this.rechargeStartDate+" 06:00:00",this.rechargeEndDate+" 06:00:00",this.rechargeLogPayment,this.rechargeLogType,this.userInfo.id,this.userInfo.guid);
                    })
                }else if(type=="withdraw"){//获取提现记录
                    if(Math.abs((new Date(this.withdrawStartDate)-new Date(this.withdrawEndDate))/1000/60/60/24)>45){
                        useLayer({str:this.$t("personal.dateMax")});
                        return;
                    }
                    this.isSearch=false;
                    this.$nextTick(()=>{
                        this.isSearch=true;
                        this.$root.wsSend(COMMAND.all_getwithdraw,this.withdrawStartDate+" 06:00:00",this.withdrawEndDate+" 06:00:00",this.withdrawLogType,this.withdrawLogState,this.userInfo.id,this.userInfo.guid);
                    })
                }
            },
            /**
             * 改变页码
             */
            changePage(page,type){
                console.log(page,type)
                this.$root.wsSend(COMMAND.all_getbetlog,this.submitLogStartDate+" 06:00:00",this.submitLogEndDate+" 06:00:00",page,30,this.submitLogGameType,this.userInfo.id,this.userInfo.guid);
            },
            /**
             * 设置提交记录注单文本
             */
            setReportHtml(text,game){
                let arr=[];
                let str="";
                if(this.submitLogGameType==GAME_ID.bac||this.submitLogGameType==GAME_ID.longfong||this.submitLogGameType==GAME_ID.coin){
                    //处理数据
                    let resultArr;
                    if(game==GAME_ID.bac){
                        resultArr=[[this.$t("common.z"),"",COLOR.redDark],[this.$t("common.x"),"",COLOR.blue],[this.$t("common.h"),"",COLOR.greenDark],[this.$t("common.zd"),"",COLOR.redDark],[this.$t("common.xd"),"",COLOR.blue]];
                    }else if(game==GAME_ID.lh){
                        resultArr=[[this.$t("common.l"),"",COLOR.redDark],[this.$t("common.hu"),"",COLOR.blue],[this.$t("common.h"),"",COLOR.greenDark]];
                    }else if(game==GAME_ID.longfong){
                        resultArr=[[this.$t("common.l"),"",COLOR.blue],[this.$t("common.fong"),"",COLOR.redDark],[this.$t("common.h"),"",COLOR.greenDark],[this.$t("common.lsg"),"",COLOR.blue],[this.$t("common.fsg"),"",COLOR.redDark]];
                    }else if(game==GAME_ID.coin){
                        resultArr=[[this.$t("common.even"),"",COLOR.redDark],[this.$t("common.odd"),"",COLOR.blue],[this.$t("common.twoFlower"),"",COLOR.redDark],[this.$t("common.twoWord"),"",COLOR.blue]];
                    }
                    arr=text.split(',');
                    arr.pop();//去掉最后的空元素
                    if(game==GAME_ID.bac){
                        for(let i=0;i<arr.length;i++){
                            arr[i].indexOf("庄:")!=-1&&(resultArr[0][1]=arr[i].replace("庄:",""));
                            arr[i].indexOf("闲:")!=-1&&(resultArr[1][1]=arr[i].replace("闲:",""));
                            arr[i].indexOf("和:")!=-1&&(resultArr[2][1]=arr[i].replace("和:",""));
                            arr[i].indexOf("庄对:")!=-1&&(resultArr[3][1]=arr[i].replace("庄对:",""));
                            arr[i].indexOf("闲对:")!=-1&&(resultArr[4][1]=arr[i].replace("闲对:",""));
                            arr[i].indexOf("龙:")!=-1&&(resultArr[0][1]=arr[i].replace("龙:",""));
                            arr[i].indexOf("虎:")!=-1&&(resultArr[1][1]=arr[i].replace("虎:",""));
                        }
                    }else if(game==GAME_ID.longfong){
                        for(let i=0;i<arr.length;i++){
                            arr[i].indexOf("龙:")!=-1&&(resultArr[0][1]=arr[i].replace("龙:",""));
                            arr[i].indexOf("凤:")!=-1&&(resultArr[1][1]=arr[i].replace("凤:",""));
                            arr[i].indexOf("和:")!=-1&&(resultArr[2][1]=arr[i].replace("和:",""));
                            arr[i].indexOf("龙三公:")!=-1&&(resultArr[3][1]=arr[i].replace("龙三公:",""));
                            arr[i].indexOf("凤三公:")!=-1&&(resultArr[4][1]=arr[i].replace("凤三公:",""));
                        }
                    }else if(game==GAME_ID.coin){
                        for(let i=0;i<arr.length;i++){
                            arr[i].indexOf("双:")!=-1&&(resultArr[0][1]=arr[i].replace("双:",""));
                            arr[i].indexOf("单:")!=-1&&(resultArr[1][1]=arr[i].replace("单:",""));
                            arr[i].indexOf("双花:")!=-1&&(resultArr[2][1]=arr[i].replace("双花:",""));
                            arr[i].indexOf("双字:")!=-1&&(resultArr[3][1]=arr[i].replace("双字:",""));
                        }
                    }
                    //拼接html
                    str+="<div class='d-flex border border-secondary rounded col-12 text-center'>";
                    for (let i = 0; i < resultArr.length; i++) {
                        str+="<div class='d-flex flex-column col'>";
                        str+="    <span class='fw-bold border-secondary border-bottom "+(i==resultArr.length-1?"":"border-end")+"' style='color:"+resultArr[i][2]+"'>"+resultArr[i][0]+"</span>";
                        str+="    <span class='col border-secondary "+(i==resultArr.length-1?"":"border-end")+"'>"+resultArr[i][1]+"</span>";
                        str+="</div>";
                    }
                    str+="</div>";
                }else if(this.submitLogGameType==GAME_ID.cor){
                    //处理数据
                    let zArr=[["","",""],["","",""],["","",""]],xArr=[["","",""],["","",""],["","",""]];//[[平1,平2,平3],[翻1,翻2,翻3],[多1,多2,多3]]
                    arr=(text+',').replace(/\.00/g,'').split('},');//P{} D{} F{}
                    arr.pop();//去掉最后的空元素
                    for (let k = 0; k < arr.length; k++) {
                        arr[k]=arr[k].replace("P{","").replace("F{","").replace("D{","").split(",");//取花括号中的内容根据逗号切割
                        arr[k].pop();//去掉最后的空元素
                        for (let l = 0; l < arr[k].length; l++) {
                            let index=arr[k][l].substring(1,2)-1;
                            if(arr[k][l].indexOf("z")!=-1){
                                zArr[k][index]=arr[k][l].substring(arr[k][l].indexOf(":")+1);
                            }else if(arr[k][l].indexOf("x")!=-1){
                                xArr[k][index]=arr[k][l].substring(arr[k][l].indexOf(":")+1);
                            }
                        }
                    }
                    //拼接html
                    str+="<div class='d-flex justify-content-center col-12'>";
                    str+="    <div class='d-flex flex-column text-nowrap justify-content-end border border-secondary rounded-start fw-bold'>";
                    str+="        <span class='col'>&nbsp;</span>";
                    str+="        <span class='border-top border-secondary border-2 px-1 col'>"+this.$t("room.singleCount")+"</span>";
                    str+="        <span class='border-top border-secondary px-1 col'>"+this.$t("room.doubleCount")+"</span>";
                    str+="        <span class='border-top border-secondary px-1 col'>"+this.$t("room.multiplesCount")+"</span>";
                    str+="    </div>";
                    str+="    <div class='d-flex flex-column text-center border border-secondary justify-content-between col'>";
                    str+="        <div class='fw-bold border-bottom border-secondary w-100' style='color:"+COLOR.red+"'>"+this.$t("common.z")+"</div>";
                    for (let m = 0; m < zArr.length; m++) {
                        str+="    <div class='d-flex border-top border-secondary col w-100'>";
                        for (let n = 0; n < zArr[m].length; n++) {
                            str+="    <span class='"+(n<zArr[m].length-1?"border-1 border-secondary border-end-style-dashed":"")+" col-4'>"+zArr[m][n]+"</span>";
                        }
                        str+="    </div>";
                    }
                    str+="    </div>";
                    str+="    <div class='d-flex flex-column text-center border border-secondary rounded-end justify-content-between col'>";
                    str+="        <div class='fw-bold border-bottom border-secondary w-100' style='color:"+COLOR.blue+"'>"+this.$t("common.x")+"</div>";
                    for (let m = 0; m < xArr.length; m++) {
                        str+="    <div class='d-flex border-top border-secondary col w-100'>";
                        for (let n = 0; n < xArr[m].length; n++) {
                            str+="    <span class='"+(n<xArr[m].length-1?"border-1 border-secondary border-end-style-dashed":"")+" col-4'>"+xArr[m][n]+"</span>";
                        }
                        str+="    </div>";
                    }
                    str+="    </div>";
                    str+="</div>";
                }
                return str;
            },
            /**
             * 设置提交记录结果文本
             */
            setResultHtml(text){
                let str="";
                if(this.submitLogGameType==GAME_ID.bac||this.submitLogGameType==GAME_ID.longfong||this.submitLogGameType==GAME_ID.coin){
                    // text=="闲"&&(str=text.replace("闲",'<span style="color:'+this.color.blue+'">'+this.$t("common.x")+'</span>'));
                    // text=="庄"&&(str=text.replace("庄",'<span style="color:'+this.color.red+'">'+this.$t("common.z")+'</span>'));
                    // text=="和"&&(str=text.replace("和",'<span style="color:'+this.color.green+'">'+this.$t("common.h")+'</span>'));
                    // text=="龙"&&(str=text.replace("龙",'<span style="color:'+this.color.red+'">'+this.$t("common.l")+'</span>'));
                    // text=="虎"&&(str=text.replace("虎",'<span style="color:'+this.color.blue+'">'+this.$t("common.hu")+'</span>'));
                    str=text;
                }else if(this.submitLogGameType==GAME_ID.cor){
                    //处理数据
                    let numArr=[this.$t("common.one"),this.$t("common.two"),this.$t("common.three"),this.$t("common.four"),this.$t("common.five"),this.$t("common.six"),this.$t("common.seven"),this.$t("common.eight"),this.$t("common.nine")];
                    let arr=text.split(',');
                    for (let i = 0; i < arr.length; i++) {
                        arr[i]=arr[i].replace("1:","").replace("2:","").replace("3:","").replace("4:","");
                        if(arr[i]==10){
                            arr[i]=this.$t("room.resultGreat");
                        }else if(arr[i]==20){
                            arr[i]=this.$t("room.resultSilver");
                        }else if(arr[i]==30){
                            arr[i]=this.$t("room.resultGold");
                        }else if(arr[i]==40){
                            arr[i]=this.$t("room.resultBomb");
                        }else if(arr[i]==0){
                            arr[i]=this.$t("room.resultNothing");
                        }else{
                            arr[i]=this.$t("app.great")+numArr[arr[i]-1];
                        }
                    }
                    //拼接html
                    str+="<div class='d-flex border-sm border-secondary rounded text-center'>";
                    str+="    <div class='d-flex flex-sm-column col-sm flex-fill'>";
                    str+="        <span class='fw-bold border-bottom-sm border-secondary' style='color:"+COLOR.red+"'>"+this.$t('common.z')+"</span>";
                    str+="        <span class='flex-grow-1 d-flex flex-column justify-content-center border-top-sm border-end-sm border-secondary'>"+arr[0]+"</span>";
                    str+="    </div>";
                    str+="    <div class='d-flex flex-sm-column col-sm flex-fill'>";
                    str+="        <span class='fw-bold border-bottom-sm border-secondary' style='color:"+COLOR.blue+"'>"+this.$t('common.x')+"</span>";
                    str+="        <span class='border-top-sm border-start-sm border-secondary flex-fill'>"+arr[1]+"</span>";
                    str+="        <span class='border-top-sm border-start-sm border-secondary flex-fill'>"+arr[2]+"</span>";
                    str+="        <span class='border-top-sm border-start-sm border-secondary flex-fill'>"+arr[3]+"</span>";
                    str+="    </div>";
                    str+="</div>";
                }
                return str;
            },
            /**
             * 获取记录查询的资金方式
             */
            getLogType(tabId,id){
                if(tabId=="recharge"){//充值
                    if(id==FUNDS_RECHARGE_TYPE.alipay){
                        return this.$t("personal.alipay");
                    }else if(id==FUNDS_RECHARGE_TYPE.bank){
                        return this.$t("personal.unionPay");
                    }else if(id==FUNDS_RECHARGE_TYPE.wechat){
                        return this.$t("personal.wechat");
                    }else if(id==FUNDS_RECHARGE_TYPE.virtual){
                        return this.$t("personal.virtualCurrency");
                    }
                }else if(tabId=="withdraw"){//提现
                    if(id==FUNDS_WITHDRAW_TYPE.alipay){
                        return this.$t("personal.alipay");
                    }else if(id==FUNDS_WITHDRAW_TYPE.bank){
                        return this.$t("personal.unionPay");
                    }else if(id==FUNDS_WITHDRAW_TYPE.wechat){
                        return this.$t("personal.wechat");
                    }else if(id==FUNDS_WITHDRAW_TYPE.virtual){
                        return this.$t("personal.virtualCurrency");
                    }
                }
            },
            /**
             * 获取记录的状态
             */
            getLogState(id){
                if(id==LOG_STATE.wait){
                    return this.$t("personal.stateWait");
                }else if(id==LOG_STATE.doing){
                    return this.$t("personal.stateDoing");
                }else if(id==LOG_STATE.ready){
                    return this.$t("personal.stateReady");
                }else if(id==LOG_STATE.fail){
                    return this.$t("personal.stateFail");
                }
            },
            /**
             * 复制媒体聊天地址
             */
            copy(text){
                copy(text).then((result)=>{
                    if(result=="success"){
                        useLayer({str:"已复制到剪贴板"});
                    }else{
                        useLayer({str:"复制失败"});
                    }
                });
            },
            /**
             * 改变音效开关
             */
            changeAudioSwitch(type,isOpen){
                this.$store.commit({
                    type:MUTATION_TYPE.setAudioSwitch,
                    audioSwitch:{
                        [type]:isOpen
                    }
                })
            }
        },
        watch:{
            //监听充值支付方式列表改变
            rechargeFundsType(val){
                if(val&&val.length>0){
                    this.payment=val[0].id;
                    this.$root.wsSend(COMMAND.all_getcoin,this.payment,this.userInfo.id,this.userInfo.guid);//获取币种(充值)
                }
            },
            //监听币种列表改变
            currencyList(val){
                if(val.length>0){
                    this.rechargeCurrency=val[0].id;
                    this.changeCurrency(val[0].id);
                }
            },
            //监听充值返回对象改变
            rechargeResult(data){
                var r=data.result;//提交结果
                if(r==RECHARGE_RESULT.error){
                    useLayer({str:this.$t("personal.rechargeErr")});
                }else if(r==RECHARGE_RESULT.moneyErr){
                    useLayer({str:this.$t("personal.rechargeAmountErr")});
                }else if(r==RECHARGE_RESULT.submitMuch){
                    useLayer({str:this.$t("personal.submitMuch")});
                }else if(r==RECHARGE_RESULT.noVip){
                    useLayer({str:this.$t("personal.noVip")});
                }else if(r==RECHARGE_RESULT.noUser){
                    useLayer({str:this.$t("personal.invalidUser")});
                }else if(r==RECHARGE_RESULT.submitAgain){
                    useLayer({str:this.$t("personal.submitAgain")});
                }else if(r==RECHARGE_RESULT.aisleErr){
                    useLayer({str:this.$t("personal.aisleErr")});
                }else if(r==RECHARGE_RESULT.bossErr){
                    useLayer({str:this.$t("personal.bossErr")});
                }else if(r==RECHARGE_RESULT.officialErr){
                    useLayer({str:this.$t("personal.officialErr")});
                }else{
                    this.rechargeAmount="";
                    this.rechargeQrcode=data.qr.qraddress;
                    this.rechargeCode=data.code;
                    this.isShowRechargeForm=false;
                    if(data.show==true){
                        this.isShowBankInfo=false;
                        this.isShowQrcode=true;
                    }
                    if(data.way==FUNDS_RECHARGE_TYPE.bank){
                        this.isShowBankInfo=true;
                        this.isShowQrcode=false;
                        this.rechargeSuccBankName=data.qr.payname;
                        this.rechargeSuccBankAccount=data.qr.payaccount;
                        this.rechargeSuccBankBank=data.qr.paybank;
                    }
                }
            },
            //监听提现支付方式列表改变
            withdrawFundsType(val){
                if(val&&val.length>0){
                    this.withdrawal=val[0].id;
                    this.changeWithdrawAccepted(this.withdrawal);
                }
            },
            //监听提现返回对象改变
            withdrawResult(data){
                var r=data.result;//提交结果 
                if(r==WITHDRAW_RESULT.noUser){
                    useLayer({str:this.$t("personal.invalidUser")});
                }else if(r==WITHDRAW_RESULT.pwdErr){
                    useLayer({str:this.$t("personal.withdrawPwdErr")});
                }else if(r==WITHDRAW_RESULT.moneyLack){
                    useLayer({str:this.$t("personal.withdrawAmountLack")});
                }else if(r==WITHDRAW_RESULT.moneyErr){
                    useLayer({str:this.$t("personal.withdrawAmountErr")});
                }else if(r==WITHDRAW_RESULT.reportErr){
                    useLayer({str:this.$t("personal.reportErr")});
                }else if(r==WITHDRAW_RESULT.acceptedErr){
                    useLayer({str:this.$t("personal.acceptedErr")});
                }else if(r==WITHDRAW_RESULT.systemErr){
                    useLayer({str:this.$t("common.service")});
                }else if(r==WITHDRAW_RESULT.error){
                    useLayer({str:this.$t("personal.withdrawErr")});
                }else{
                    useLayer({str:this.$t("personal.withdrawSuccess")});
                    this.$store.commit({
                        type:MUTATION_TYPE.setUserInfo,
                        userInfo:{
                            realCoin:this.userInfo.realCoin-this.withdrawAmount
                        }
                    })
                    this.withdrawAmount="";
                    this.withdrawPwd="";
                }
            },
            //监听修改密码返回结果
            updatepwdResult(data){
                var r=data.result;
                if(r==UPDATE_PWD_RESULT.success){
                    useLayer({
                        type:LAYER_TYPE.confirm,
                        str:this.$t("personal.updLoginPwdSuccess"),
                        btn:[this.$t("common.ok")],
                        yesFn:(i)=>{
                            this.$root.wsSend(COMMAND.all_logout, this.userInfo.id, this.userInfo.guid);
                            layer.close(i);
                            this.$router.push("/");
                        }
                    })
                    this.currPwdType="";
                    this.oldPwd="";
                    this.newPwd="";
                    this.againPwd="";
                }else if(r==UPDATE_PWD_RESULT.pwdEmpty){
                    useLayer({str:this.$t("personal.pwdEmpty")});
                }else if(r==UPDATE_PWD_RESULT.pwdErr){
                    useLayer({str:this.$t("personal.pwdErr")});
                }else if(r==UPDATE_PWD_RESULT.userErr){
                    useLayer({str:this.$t("personal.invalidUser")});
                }else if(r==UPDATE_PWD_RESULT.pwdEqual){
                    useLayer({str:this.$t("personal.pwdEqual")});
                }
            },
            //监听问题反馈返回结果
            addquestionResult(data){
                let id=data.result;
                if(id>0){
                    this.txtProblemTitle="";
                    this.txtProblemContent="";
                    //获取问题反馈
                    this.$root.wsSend(COMMAND.all_getquestion,1,100,this.userInfo.id,this.userInfo.guid);
                }else{
                    useLayer({str:this.$t("personal.sendErr")});
                }
            },
            //监听操作用户资金方式返回结果
            setuserchannelResult(data){
                var r=data.result;
                if(r==SET_SOURCE_RESULT.error){
                    useLayer({str:this.$t("common.subErr")});
                }else if(r==SET_SOURCE_RESULT.cannot){
                    useLayer({str:this.$t("personal.accountLimit")});
                }else if(r==SET_SOURCE_RESULT.noCurrency){
                    useLayer({str:this.$t("personal.invalidCurrency")});
                }else{
                    if(data.type==FUNDS_WITHDRAW_TYPE.alipay){
                        this.sourceAlipayList.push({
                            id:r,
                            user:this.addAlipayAccount,
                            name:this.addAlipayName
                        })
                        this.addAlipayName="";
                        this.addAlipayAccount="";
                        this.isShowAddAlipay=false;
                    }else if(data.type==FUNDS_WITHDRAW_TYPE.bank){
                        this.sourceBankList.push({
                            id:r,
                            user:this.addBankAccount,
                            name:this.addBankName,
                            bankname:this.addBankBank
                        })
                        this.addBankName="";
                        this.addBankAccount="";
                        this.addBankBank="";
                        this.isShowAddBank=false;
                    }else if(data.type==FUNDS_WITHDRAW_TYPE.virtual){
                        this.sourceCoinList.push({
                            id:r,
                            type:this.addVirtualCurrency,
                            name:this.addVirtualCurrencyName,
                            url:this.addVirtualUrl
                        })
                        this.addVirtualCurrency="";
                        this.addVirtualCurrencyName="";
                        this.isShowAddCoin=false;
                    }
                    useLayer({str:this.$t("common.subSuccess")});
                }
            },
            //监听删除用户资金方式返回结果
            deluserchannelResult(data){
                var r=data.result;
                if(r==SET_SOURCE_RESULT.error){
                    useLayer({str:this.$t("common.subErr")});
                }else if(r==SET_SOURCE_RESULT.cannot){
                    useLayer({str:this.$t("personal.sourceHasBills")});
                }else if(r==SET_SOURCE_RESULT.noCurrency){
                    useLayer({str:this.$t("personal.invalidCurrency")});
                }else{
                    if(this.setFundsType==FUNDS_WITHDRAW_TYPE.alipay){
                        for(let i=0;i<this.sourceAlipayList.length;i++){
                            this.sourceAlipayList[i].id==r&&this.sourceAlipayList.splice(i,1);
                        }
                    }else if(this.setFundsType==FUNDS_WITHDRAW_TYPE.bank){
                        for(let i=0;i<this.sourceBankList.length;i++){
                            this.sourceBankList[i].id==r&&this.sourceBankList.splice(i,1);
                        }
                    }else if(this.setFundsType==FUNDS_WITHDRAW_TYPE.virtual){
                        for(let i=0;i<this.sourceCoinList.length;i++){
                            this.sourceCoinList[i].id==r&&this.sourceCoinList.splice(i,1);
                        }
                    }
                    useLayer({str:this.$t("common.subSuccess")});
                }
            },
            //监听提交记录数据改变
            getbetlogData(data){
                this.currPage=data.pagecurrent;
                this.countPage=data.pagelen;
                this.submitLog=data.list;
                this.submitLogTotal=data.allbet;
                this.submitLogValid=data.validbet;
                this.submitLogWin=data.win;
                this.isSearch=false;
            },
            //监听充值记录数据改变
            getrechargeData(data){
                this.rechargeLog=data.list;
                this.isSearch=false;
            },
            //监听提现记录数据改变
            getwithdrawData(data){
                this.withdrawLog=data.list;
                this.isSearch=false;
            }
        }
    }
</script>