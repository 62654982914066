<!-- 登录页 -->
<style>
    body{
        background:none;
    }
    .login-bg{
        background: url(@/assets/images/login/login_bg.jpg) no-repeat 30% top;
        background-size: cover;
    }
    .verify-wrap{
        height: 2.373rem;
    }
    .verify-msg{
        display:none;
    }
    .suc-drag-btn>.bi-suit-spade-fill{
        display: none;
    }
    .bi-check-circle-fill{
        display: none;
        color: #7ac23c;
    }
    .suc-drag-btn>.bi-check-circle-fill{
        display: initial;
    }
    .drag-progress,.verify-msg{
        background: #7ac23c;
    }
    .language-more{
        -webkit-transition: all .3s cubic-bezier(.25, 1.15, .35, 1.15);
        -moz-transition: all .3s cubic-bezier(.25, 1.15, .35, 1.15);
        -o-transition: all .3s cubic-bezier(.25, 1.15, .35, 1.15);
        -ms-transition: all .3s cubic-bezier(.25, 1.15, .35, 1.15);
        transition: all .3s cubic-bezier(.25, 1.15, .35, 1.15);
        opacity: 0;
        transform: translateY(-20px);
    }
    .language-more.active{
        opacity: 1;
        transform: translateY(0px);
    }
    .cursor-move{
        cursor: move;
    }
    .text-discolor{
        color: green;
        position: relative;
        /* background: linear-gradient(90deg, #fff 0%, green 100%);
        background-clip: text; */
    }
    .text-discolor::before{
        content: attr(text);
        position: absolute;
        color: #fff;
        mask-image: linear-gradient(90deg, #fff 0%, transparent 100%);
        animation:text-discolor 2s infinite;
    }
    .bi[class^="bi-fullscreen"]::before{
        font-weight: bold !important;
    }
    /* 字体逐一变色特效 */
    @keyframes text-discolor{
        0%{
            mask-image: linear-gradient(90deg, #fff 0%, transparent 100%);
        }
        10%{
            mask-image: linear-gradient(90deg, transparent 0%, #fff 10%, transparent 100%);
        }
        20%{
            mask-image: linear-gradient(90deg, transparent 0%, #fff 20%, transparent 100%);
        }
        30%{
            mask-image: linear-gradient(90deg, transparent 0%, #fff 30%, transparent 100%);
        }
        40%{
            mask-image: linear-gradient(90deg, transparent 0%, #fff 40%, transparent 100%);
        }
        50%{
            mask-image: linear-gradient(90deg, transparent 0%, #fff 50%, transparent 100%);
        }
        50%{
            mask-image: linear-gradient(90deg, transparent 0%, #fff 60%, transparent 100%);
        }
        70%{
            mask-image: linear-gradient(90deg, transparent 0%, #fff 70%, transparent 100%);
        }
        80%{
            mask-image: linear-gradient(90deg, transparent 0%, #fff 80%, transparent 100%);
        }
        90%{
            mask-image: linear-gradient(90deg, transparent 0%, #fff 90%, transparent 100%);
        }
        100%{
            mask-image: linear-gradient(90deg, transparent 0%, #fff 100%);
        }
    }
</style>
<template>
    <div class="login-bg d-flex flex-column h-100 justify-content-center position-relative">
        <!-- 功能框 -->
        <div class="position-absolute top-2rem start-2rem index-1">
            <!-- 全屏 -->
            <button class="btn bg-dark bg-opacity-75 text-light mb-2 d-flex align-items-center" type="button" @click="$root.setFullscreen">
                <i :class="[isFullscreen?'bi-fullscreen-exit':'bi-fullscreen']" class="bi d-flex" role="button"></i>
                <span class="ms-1 fw-bold">{{isFullscreen?$t("login.unFullscreen"):$t("login.fullscreen")}}</span>
            </button>
            <!-- 语言选择 -->
            <choose-lang></choose-lang>
        </div>
        <!-- 登录框 -->
        <div class="d-flex justify-content-center">
            <div class="col-9 col-sm-4 bg-dark bg-opacity-75 rounded-5">
                <form class="p-3">
                    <!-- 用户名 -->
                    <div class="mb-3">
                        <div class="input-group input-group-lg">
                            <span class="input-group-text border-warning bg-transparent border border-end-0">
                                <i class="bi bi-person-fill fs-4 lh-1 text-warning"></i>
                            </span>
                            <input type="text" class="form-control bg-transparent text-light border-warning border-start-0 ps-0 shadow-none" :placeholder="$t('login.userName')" v-model="userName">
                        </div>
                    </div>
                    <!-- 密码 -->
                    <div class="mb-3">
                        <div class="input-group input-group-lg">
                            <span class="input-group-text border-warning bg-transparent border border-end-0">
                                <i class="bi bi-lock-fill fs-4 lh-1 text-warning"></i>
                            </span>
                            <input type="password" class="form-control bg-transparent text-light border-warning border-start-0 ps-0 shadow-none" :placeholder="$t('login.pwd')" v-model="pwd">
                        </div>
                    </div>
                    <!-- 验证码 -->
                    <div v-if="verify==verifyType.code" class="mb-3 row">
                        <div class="col-8">
                            <input type="text" class="form-control" id="pwd" :placeholder="$t('login.code')" v-model="txtCode">
                        </div>
                        <div class="col-4 d-flex flex-column">
                            <canvas id="code" class="d-block bg-light rounded-2 w-100 h-0 flex-grow-1" width="0" height="0" @click="code=drawCode($event.target)"></canvas>
                        </div>
                    </div>
                    <!-- 滑动验证 -->
                    <div v-if="verify==verifyType.slide" class="mb-3 rounded-2">
                        <div id="slideVerify" class="verify-wrap rounded border border-success position-relative"></div>
                    </div>
                    <!-- 游戏模式 -->
                    <div class="mb-2 text-light">
                        <label>{{$t("login.gameModel")}}</label>
                        <div class="form-check-inline">
                            <input class="form-check-input me-1" type="radio" name="gameModel" :checked="gameModel==GAME_MODEL.normal" @change="changeGameModel(GAME_MODEL.normal)" id="normGame">
                            <label class="form-check-label" for="normGame">{{$t("login.classics")}}</label>
                        </div>
                        <div class="form-check-inline">
                            <input class="form-check-input me-1" type="radio" name="gameModel" :checked="gameModel==GAME_MODEL.tel" @change="changeGameModel(GAME_MODEL.tel)" id="telGame">
                            <label class="form-check-label" for="telGame">{{$t("login.tel")}}</label>
                        </div>
                    </div>
                    <!-- 色调选择 -->
                    <div class="mb-2 text-light">
                        <label>{{$t("login.colorType")}}</label>
                        <div class="form-check-inline">
                            <input class="form-check-input me-1" type="radio" name="colorType" :checked="!$root.skin" id="normColor" @change="$emit('set-skin','')">
                            <label class="form-check-label" for="normColor">{{$t("login.light")}}</label>
                        </div>
                        <div class="form-check-inline">
                            <input class="form-check-input me-1" type="radio" name="colorType" :checked="$root.skin=='darkness'" id="darkColor" @change="$emit('set-skin','darkness')">
                            <label class="form-check-label" for="darkColor">{{$t("login.dark")}}</label>
                        </div>
                    </div>
                    <!-- 验证方式 -->
                    <div class="mb-2 text-light">
                        <label>{{$t("login.verifyType")}}</label>
                        <div class="form-check-inline">
                            <input class="form-check-input me-1" type="radio" name="checkType" :value="verifyType.slide" id="checkSlide" v-model="verify" @change="changeVerify">
                            <label class="form-check-label" for="checkSlide">{{$t("login.slider")}}</label>
                        </div>
                        <div class="form-check-inline">
                            <input class="form-check-input me-1" type="radio" name="checkType" :value="verifyType.code" id="checkCode" v-model="verify" @change="changeVerify">
                            <label class="form-check-label" for="checkCode">{{$t("login.code")}}</label>
                        </div>
                    </div>
                    <!-- 线路选择 -->
                    <div class="mb-3 text-light">
                        <label>{{$t("login.chooseNetwork")}}</label>
                        <div v-for="(item,key,i) in wsList" :key="(item,key,i)" class="form-check-inline">
                            <input class="form-check-input me-1" type="radio" :value="item" v-model="ws" name="wsline" :id="'wsLine_'+i">
                            <label class="form-check-label" :for="'wsLine_'+i">{{key}}</label>
                        </div>
                    </div>
                    <!-- 登录按钮 -->
                    <div class="d-flex">
                        <button type="button" class="btn btn-warning btn-block btn-lg fw-bold me-3 flex-grow-1" @click="login(LOGIN_MODE.normal)">{{$t("login.login")}}</button>
                        <button v-if="isShowTry" type="button" class="btn btn-outline-info btn-block btn-lg fw-bold" @click="login(LOGIN_MODE.try)"><i class="bi bi-controller me-2"></i>{{$t('login.tryGame')}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import slideVerifyPlug from '@/assets/js/jq-slideVerify.js'
import {COMMAND,RESULT,LAYER_TYPE,IS_DEBUG,USER,LOGIN_MODE,MUTATION_TYPE,GAME_MODEL} from "@/assets/js/config.js"
import { useLayer } from '@/assets/js/useLayer';
import { getDeviceType,drawCode,getSystem } from '@/assets/js/common.js'
import chooseLang from "@/components/public/ChooseLang.vue";
export default {
    components: { chooseLang },
    props:{
        quickUserInfo:{
            default:[]
        },//快捷登录用户信息
        isFullscreen:{
            type:Boolean,
            default:false
        },//当前是否全屏
    },
    data() {
        return {
            USER,
            LOGIN_MODE,
            GAME_MODEL,
            verifyType:{//验证类型
                "slide":0,//滑块验证
                "code":1,//验证码验证
            },
            userName: this.quickUserInfo[0]||"",//用户名
            pwd:this.quickUserInfo[1]||"",//密码
            verify:0,//验证方式(默认为滑动验证方式)
            slideVerify:null,//滑动验证实例对象
            txtCode:"",//用户输入的验证码
            code:"",//生成的验证码
            wsList:window[USER].ws,//config中配置的ws列表
            ws:"",//用户选中的ws线路地址
            isFullScreen:false,//是否全屏
            isShowTry:window[USER].isShowTry,//是否显示免费试用按钮
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        },
        gameModel(){
            return this.$store.state.gameModel;
        }
    },
    created() {
        //设置默认选中第一个ws协议
        for (let key in this.wsList) {
            this.ws=this.wsList[key];
            break;
        }
    },
    mounted() {
        //如果访问登录页面的时候有guid，说明可能是点击浏览器的返回,需要先注销登录，否则可能被推送账号多方登录
        (this.userInfo.guid!="")&&this.$emit('ws-send', COMMAND.all_logout, this.userInfo.id, this.userInfo.guid);
        //绑定回车事件
        document.onkeyup=e=>(e.keyCode==13&&this.login(this.LOGIN_MODE.normal));
        //初始化滑动验证
        this.initSlideVerify();
        //填充测试账号
        (IS_DEBUG&&!this.userName&&!this.pwd)&&this.fillDebugUser();
    },
    unmounted() {
        document.onkeyup=null;//清空回车事件，释放内存并防止在别的页面仍能触发
    },
    methods:{
        drawCode,
        /**
         * 初始化滑动验证
         */
        initSlideVerify(){
            this.slideVerify = new slideVerifyPlug('slideVerify', {
                initText:this.$t("login.slideTip"),
                sucessText:this.$t("login.slideSuccessTip")
            });
        },
        /**
         * 登录
         * @param {LOGIN_MODE} type 登录方式
         */
        login(type){
            //登录验证
            if(type==LOGIN_MODE.normal){//正常登录
                if(this.userName==""){
                    useLayer({str:this.$t("login.userEmpty")});
                    return;
                }else if(this.pwd==""){
                    useLayer({str:this.$t("login.pwdEmpty")});
                    return;
                }
            }
            if (this.verify==this.verifyType.slide){//滑动验证
                if(this.slideVerify.slideFinishState == false){//验证没有通过
                    useLayer({str:this.$t("login.slideVerify")});
                    return;
                }
            }else if(this.verify==this.verifyType.code){//验证码验证
                if(this.txtCode!=this.code){
                    useLayer({str:this.$t("login.codeVerify")});
                    return;
                }
            }
            //登录
            let fn=()=>{
                useLayer({
                    type:LAYER_TYPE.load,
                    str:this.$t("login.logining")
                })
                let ws=new WebSocket(this.ws);
                this.$root.ws=ws;
                ws.onopen= ()=> {
                    IS_DEBUG&&console.log("连接建立");
                    if(type==LOGIN_MODE.try){//试玩登录
                        this.$emit("ws-send",COMMAND.all_gettryuser,window[USER].tryBelong);
                    }else{
                        if(this.gameModel==GAME_MODEL.normal){
                            this.$emit("ws-send",COMMAND.all_login, this.userName, this.pwd, getDeviceType(), this.$root.ip.cip,"abcdefg",this.quickUserInfo?LOGIN_MODE.quick:LOGIN_MODE.normal);
                        }else if(this.gameModel==GAME_MODEL.tel){//电话模式
                            this.$emit("ws-send",COMMAND.tel_login, this.userName, this.pwd, getDeviceType(), this.$root.ip.cip,"abcdefg",this.quickUserInfo?LOGIN_MODE.quick:LOGIN_MODE.normal);
                        }
                    }
                }
                ws.onclose=(e)=>{
                    if (e.code == 1006) {//用于期望收到状态码时连接非正常关闭 (也就是说, 没有发送关闭帧).
                        if (this.$route.name=="login") {//在登录页面
                            layer.closeAll();
                            useLayer({str:this.$t("login.wsError")});
                        }
                    }
                    this.$root.ws=null;
                }
                this.$root.onMessage();
                this.$emit("ws-msg",(data)=>{
                    switch (data.command) {
                        case COMMAND.all_login:
                        case COMMAND.tel_login:
                            let result=data.result;//登录结果
                            if (result==RESULT.login.success) {
                                this.$emit("ws-send",COMMAND.all_online, this.userName, data.guid);
                                this.$store.commit({
                                    type:MUTATION_TYPE.setUserInfo,
                                    userInfo:{
                                        id:this.userName,
                                        guid:data.guid
                                    }
                                })
                                this.$root.goPage("home");
                            }else if(result!=""&&(Number(result)==RESULT.login.unUser||Number(result)==RESULT.login.unPwd)){
                                useLayer({str:this.$t("login.loginErr")});
                            }else{
                                useLayer({str:this.$t("login.loginFail")});
                            }
                            break;
                        case COMMAND.all_gettryuser:
                            if(data.result==RESULT.getTryUser.success){
                                this.userName=data.userid;
                                this.pwd=data.pwd;
                                this.$emit("set-istryuser",true);
                                this.$emit("ws-send",COMMAND.all_login, data.userid, data.pwd, getDeviceType(), this.$root.ip.cip,"abcdefg",LOGIN_MODE.try);
                            }else if(data.result==RESULT.getTryUser.empty){
                                useLayer({str:this.$t("login.unTryUser")});
                            }
                            break;
                    }
                })
            }
            if(this.gameModel==GAME_MODEL.tel){//有游戏模式，即为电话
                if(this.userName&&(this.userName.length<6||this.userName.length>8||!/^\d+$/.test(this.userName))){//用户名小于6位或者大于8位
                    useLayer({type:LAYER_TYPE.confirm,str:this.$t("login.noTelUser")+this.$t("login.isTelUser"),btn:[this.$t("common.yes"),this.$t("common.no")], yesFn(){fn();}});
                    return;
                }
            }
            fn();
        },
        /**
         * 切换验证方式
         */
        changeVerify(){
            if(this.verify==this.verifyType.code){//验证码验证
                this.code=drawCode(document.getElementById("code"));
            }else if(this.verify==this.verifyType.slide){//滑动验证
                this.initSlideVerify();
            }
        },
        /**
         * 改变游戏模式
         */
        changeGameModel(model){
            this.$store.commit({
                type:MUTATION_TYPE.setGameModel,
                gameModel:model
            })
        },
        /**
         * 测试模式自动填充用户
         */
        fillDebugUser(){
            this.userName="a11";
            if(getDeviceType()=="h5_phone_4"){
                getSystem()=="ios"&&(this.userName="a11");
                getSystem()=="android"&&(this.userName="a13");
            }
            this.pwd="000000";
            this.slideVerify.slideFinishState=true;
        }
    },
    watch: {
        /**
         * 监听游戏模式变化
         */
        gameModel(val) {
            if(IS_DEBUG){
                if(val){//电话模式
                    this.userName="888888";
                    this.pwd="888888";
                }else{
                    this.fillDebugUser();
                }
            }
            if(val){//电话模式
                if(this.userName&&(this.userName.length<6||this.userName.length>8||!/^\d+$/.test(this.userName))){
                    useLayer({str:this.$t("login.noTelUser")});
                }
            }
        },
        /**
         * 监听快速登录信息变化
         */
        quickUserInfo:{
            handler(val){
                if(val){
                    val[0]&&(this.userName=val[0]);
                    val[1]&&(this.pwd=val[1]);
                    if(this.userName&&this.pwd){
                        this.$nextTick(()=>{
                            this.slideVerify.slideFinishState=true;
                            this.login(this.LOGIN_MODE.quick);
                        })
                    }
                }
            },
            immediate:true
        },
        /**
         * 监听语言改变
         */
        "$i18n.locale"(){
            this.verify==this.verifyType.slide&&this.initSlideVerify();//重置滑动避免语言不改变
        }
    },
}
</script>